<template>
  <div class="sidebar-wrapper" data-simplebar="true">
    <div class="sidebar-header">
      <div>
        <img
          src="../../../../public/core/assets/images/logo-icon.png"
          class="logo-icon"
          alt="logo icon"
        />
      </div>
      <div>
        <h4 class="logo-text">JARS</h4>
      </div>
      <div class="toggle-icon ms-auto">
        <i class="bx bx-arrow-to-left"></i>
      </div>
    </div>
    <ul class="metismenu" id="menu">
      <li>
        <a href="javascript:void(0)" @click="$router.push('/home')">
          <div class="parent-icon">
            <i class="bx bxs-dashboard"></i>
          </div>
          <div class="menu-title">Dashboard</div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/store')">
          <div class="parent-icon">
            <i class="bx bxs-store"></i>
          </div>
          <div class="menu-title">Store</div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/retailer')">
          <div class="parent-icon">
            <i class="bx bxs-widget"></i>
          </div>
          <div class="menu-title">Retailer Service</div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/retailer-aiq')">
          <div class="parent-icon">
            <i class="bx bxs-up-arrow"></i>
          </div>
          <div class="menu-title">Retailer AIQ Service</div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/featured-brand')">
          <div class="parent-icon">
            <i class="bx bxs-extension"></i>
          </div>
          <div class="menu-title">Featured Brands</div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/category')">
          <div class="parent-icon">
            <i class="bx bxs-category-alt"></i>
          </div>
          <div class="menu-title">Category Service</div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/feedback')">
          <div class="parent-icon">
            <i class="bx bxs-comment-edit"></i>
          </div>
          <div class="menu-title">Feedback</div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/product-review')">
          <div class="parent-icon">
            <i class="bx bxs-message-alt-dots"></i>
          </div>
          <div class="menu-title">Product Review</div>
        </a>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="bx bxs-news"></i>
          </div>
          <div class="menu-title">Press Release</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/press-release')">
              <i class="bx bx-right-arrow-alt"></i>Press Release
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/press-release-category')">
              <i class="bx bx-right-arrow-alt"></i>Press Release Category
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/contact')">
          <div class="parent-icon">
            <i class="bx bxs-message-alt-detail"></i>
          </div>
          <div class="menu-title">Contact</div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/contact-reason')">
          <div class="parent-icon">
            <i class='bx bx-aperture'></i>
          </div>
          <div class="menu-title">Contact Reason</div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/synchronize')">
          <div class="parent-icon">
            <i class="bx bxs-analyse"></i>
          </div>
          <div class="menu-title">Synchronize</div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/shop-page')">
          <div class="parent-icon">
            <i class="bx bxs-shopping-bags"></i>
          </div>
          <div class="menu-title">Shop Page</div>
        </a>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="fadeIn animated bx bxs-user-rectangle"></i>
          </div>
          <div class="menu-title">User</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/admins')">
              <i class="bx bx-right-arrow-alt"></i>Admins
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/customers')">
              <i class="bx bx-right-arrow-alt"></i>Customers
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="fadeIn animated bx bxs-briefcase-alt-2"></i>
          </div>
          <div class="menu-title">Career</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/career')">
              <i class="bx bx-right-arrow-alt"></i>Career List
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/career-applicant')">
              <i class="bx bx-right-arrow-alt"></i>Career Applicant
            </a>
          </li>
          <!-- <li>
            <a href="javascript:void(0)" @click="$router.push('/career-department')">
              <i class="bx bx-right-arrow-alt"></i>Career Department
            </a>
          </li>-->
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class='bx bx-home-smile'></i>
          </div>
          <div class="menu-title">Home</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/home-page-sub-nav')">
              <i class="bx bx-right-arrow-alt"></i>Home Page Sub Nav
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="bx bxs-pen"></i>
          </div>
          <div class="menu-title">Article</div>
        </a>
        <ul>
          <!-- <li>
                        <a href="javascript:void(0)" @click="$router.push('/blog-category')">
                            <i class="bx bx-right-arrow-alt"></i>Article Category
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="$router.push('/blog-tag')">
                            <i class="bx bx-right-arrow-alt"></i>Article Tag
                        </a>
          </li>-->
          <li>
            <a href="javascript:void(0)" @click="$router.push('/blog-post')">
              <i class="bx bx-right-arrow-alt"></i>Article Post
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/article-category')">
              <i class="bx bx-right-arrow-alt"></i>Article Category
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="fa-solid fa-list-check"></i>
          </div>
          <div class="menu-title">SEO</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/site-page-title-cms')">
              <i class="bx bx-right-arrow-alt"></i>Site Page Title CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/site-page-meta-cms')">
              <i class="bx bx-right-arrow-alt"></i>Site Page Meta CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/site-map')">
              <i class="bx bx-right-arrow-alt"></i>Site Map
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-meta-mapping')">
              <i class="bx bx-right-arrow-alt"></i>Product Details Page Meta
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/robot-txt-cms')">
              <i class="bx bx-right-arrow-alt"></i>Robot Text
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/page-schema')">
              <i class="bx bx-right-arrow-alt"></i>Page Schema
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="bx bxs-cog"></i>
          </div>
          <div class="menu-title">CMS Setting</div>
        </a>
        <ul>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-home-alt"></i>
              </div>
              <div class="menu-title">Home</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/home-slider-states')">
                  <!-- <a href="javascript:void(0)" @click="$router.push('/state-list')"> -->
                  <i class="bx bx-right-arrow-alt"></i>Home Slider
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-two-states')">
                  <i class="bx bx-right-arrow-alt"></i>Section Two
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-three-states')">
                  <i class="bx bx-right-arrow-alt"></i>Section Three
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-four-states')">
                  <i class="bx bx-right-arrow-alt"></i>Section Four
                </a>
              </li>
              <!-- <li>
                <a href="javascript:void(0)" @click="$router.push('/section-four-slider-states')">
                  <i class="bx bx-right-arrow-alt"></i>Section Four Slider
                </a>
              </li>-->
              <li>
                <a href="javascript:void(0)" @click="$router.push('/common-banner-states')">
                  <i class="bx bx-right-arrow-alt"></i>Common Banner
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/new-drop-banner-states')">
                  <i class="bx bx-right-arrow-alt"></i>New Drop Banner
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-five-states')">
                  <i class="bx bx-right-arrow-alt"></i>Section Five
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-seven-states')">
                  <i class="bx bx-right-arrow-alt"></i>Section Seven
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-eight-states')">
                  <i class="bx bx-right-arrow-alt"></i>Section Eight
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-nine-states')">
                  <i class="bx bx-right-arrow-alt"></i>Section Nine
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/instagram-section-states')">
                  <i class="bx bx-right-arrow-alt"></i>Instagram CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-copy-alt"></i>
              </div>
              <div class="menu-title">Header Footer</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/header-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Header CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/footer-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Footer CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/header-banner-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Header Banner CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/privacy-policy-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Privacy Policy
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/terms-of-service-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Terms of Service
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/cookies-settings-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Cookies Settings
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-info-circle"></i>
              </div>
              <div class="menu-title">About</div>
            </a>
            <ul>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/about-page-header-section-cms')"
                >
                  <i class="bx bx-right-arrow-alt"></i>Header Section CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/about-page-section-one-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section One CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/about-page-section-two-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Two CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/about-page-section-three-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Three CMS
                </a>
              </li>
              <!-- <li>
                                <a href="javascript:void(0)" @click="$router.push('/about-page-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Page CMS
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click="$router.push('/about-section')">
                                    <i class="bx bx-right-arrow-alt"></i>Page Section Two
                                </a>
              </li>-->
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-briefcase-alt-2"></i>
              </div>
              <div class="menu-title">Career</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/career-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Page CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-select-multiple"></i>
              </div>
              <div class="menu-title">Quiz</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/quiz')">
                  <i class="bx bx-right-arrow-alt"></i>Quiz List
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/quiz-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Page CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-cart-alt"></i>
              </div>
              <div class="menu-title">Cart</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/high-deals-cms')">
                  <i class="bx bx-right-arrow-alt"></i>High Deals
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/cart-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Page CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-spreadsheet"></i>
              </div>
              <div class="menu-title">Product Details</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/how-to-enjoy-cms')">
                  <i class="bx bx-right-arrow-alt"></i>How to Enjoy CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/related-products-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Related Products CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/discover-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Discover CMS
                </a>
              </li>
              <!-- <li>
                                <a href="javascript:void(0)" @click="$router.push('/reserve-time-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Reserve Time CMS
                                </a>
              </li>-->
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-question-mark"></i>
              </div>
              <div class="menu-title">FAQ</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/faq')">
                  <i class="bx bx-right-arrow-alt"></i>FAQ
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/faq-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Page CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-shopping-bag"></i>
              </div>
              <div class="menu-title">JARS+</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/section-one-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section One CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/steps-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Steps Section CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/benefits-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Benefits Section CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/benefits-modal')">
                  <i class="bx bx-right-arrow-alt"></i>Benefits Modal
                </a>
              </li>
              <!-- <li>
                                <a href="javascript:void(0)" @click="$router.push('/section-two-top')">
                                    <i class="bx bx-right-arrow-alt"></i>Section Two Top
                                </a>
              </li>-->
              <!-- <li>
                                <a href="javascript:void(0)" @click="$router.push('/section-two-bottom')">
                                    <i class="bx bx-right-arrow-alt"></i>Section Two Bottom
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click="$router.push('/section-three')">
                                    <i class="bx bx-right-arrow-alt"></i>Section Three
                                </a>
              </li>-->
            </ul>
          </li>

          <li>
            <a href="javascript:void(0)" @click="$router.push('/user-page-banner-cms')">
              <i class="bx bx-right-arrow-alt"></i>User Page Banner CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/service-type-cms')">
              <i class="bx bx-right-arrow-alt"></i>Service Type CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/age-verification-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>Age Verification CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/landing-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>Landing Page CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/article-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>Article Page CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/article-details-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>Article Details CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/press-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>Press Release CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/contact-page-store-list')">
              <i class="bx bx-right-arrow-alt"></i>Contact Page CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/social-link')">
              <i class="bx bx-right-arrow-alt"></i>Social Link
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="bx bxs-component"></i>
          </div>
          <div class="menu-title">Component UI</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/home-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Home Page
            </a>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-briefcase-alt-2"></i>
              </div>
              <div class="menu-title">Career</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/career-page-component-ui')">
                  <i class="bx bx-right-arrow-alt"></i>Career Page
                </a>
              </li>
              <!-- <li>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/career-details-page-component-ui')"
                >
                  <i class="bx bx-right-arrow-alt"></i>Career Details Page
                </a>
              </li> -->
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-book-content"></i>
              </div>
              <div class="menu-title">Article</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/article-page-component-ui')">
                  <i class="bx bx-right-arrow-alt"></i>Article Page
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/article-details-page-component-ui')"
                >
                  <i class="bx bx-right-arrow-alt"></i>Article Details Page
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-news"></i>
              </div>
              <div class="menu-title">Press</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/press-page-component-ui')">
                  <i class="bx bx-right-arrow-alt"></i>Press Page
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/press-details-page-component-ui')"
                >
                  <i class="bx bx-right-arrow-alt"></i>Press Details Page
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-news"></i>
              </div>
              <div class="menu-title">Product Details</div>
            </a>
            <ul>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/discover-highlights-component-ui')"
                >
                  <i class="bx bx-right-arrow-alt"></i>Discover Highlights
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/about-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>About Page
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              @click="$router.push('/terms-condition-page-component-ui')"
            >
              <i class="bx bx-right-arrow-alt"></i>Terms Condition Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/privacy-policy-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Privacy Policy Page
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              @click="$router.push('/cookies-settings-page-component-ui')"
            >
              <i class="bx bx-right-arrow-alt"></i>Cookies Settings Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/contact-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Contact Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/faq-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Faq Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/landing-page-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Landing Page
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/top-ticker-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Top Ticker
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/user-page-banner-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>User Page Banner
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>