<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i class="bx bx-home-alt"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Instagram Section</li>
          </ol>
        </nav>
      </div>
      <h6 class="mb-0 text-uppercase">State List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>State Name</th>
                  <th>State Code</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.stateName }}</td>
                  <td>{{ data.stateCode }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a
                        href="javascript:void(0)"
                        class="edit-button"
                        @click="$router.push({path:'/instagram-section-cms', query:{stateCode:data.stateCode, stateName:data.stateName}})"
                      >
                        <i class="bx bxs-edit text-white"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>State Name</th>
                  <th>State Code</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/instagram-state-list.js'></script>

<style scoped>
@import "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
.metaModal {
  color: black;
  cursor: pointer;
  text-decoration: none;
  display: block;
}
.metaModal:hover {
  cursor: pointer;
  color: #09f;
}
</style>
