<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Synchronize
                        </li>
                    </ol>
                </nav>
                <div class="ms-auto">
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <h6 class="mb-0">Synchronize</h6>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-6 mx-auto">
                    <div class="card">
                        <div class="card-body">
                            <div class="text-center">
                                <div class="border border-3 p-4 rounded">
                                    <h5 class="text-center pt-4"><b>Dutchie Product Sync API</b></h5>
                                    <button @click="getDutchieSynchronizeData()" :class="dutchieButtonClass" :disabled="dutchieButtonFlag == true"><i :class='dutchieButtonIconClass'></i>{{ dutchieButtonText }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mx-auto">
                    <div class="card">
                        <div class="card-body">
                            <div class="text-center">
                                <div class="border border-3 p-4 rounded">
                                    <h5 class="text-center pt-4"><b>Dutchie Retailer Sync API</b></h5>
                                    <button @click="getRetailerSynchronizeData()" :class="retailerButtonClass" :disabled="retailerButtonFlag == true"><i :class='retailerButtonIconClass'></i>{{ retailerButtonText }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="row">
                <div class="col-md-6 mx-auto">
                    <div class="card">
                        <div class="card-body">
                            <div class="text-center">
                                <div class="border border-3 p-4 rounded">
                                    <h5 class="text-center pt-4"><b>Elastic Product Sync API</b></h5>
                                    <button @click="getElasticSearchData()" :class="elasticButtonClass" :disabled="elasticButtonFlag == true"><i :class='elasticButtonIconClass'></i>{{ elasticButtonText }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mx-auto">
                    <div class="card">
                        <div class="card-body">
                            <div class="text-center">
                                <div class="border border-3 p-4 rounded">
                                    <h5 class="text-center pt-4"><b>Elastic Retailer Sync API</b></h5>
                                    <button @click="getRetailerElasticSearchData()" :class="elasticRetailerButtonClass" :disabled="elasticRetailerButtonFlag == true"><i :class='elasticRetailerButtonIconClass'></i>{{ elasticRetailerButtonText }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12" style="text-align: center;">
                    <h3><code>Note: If you don't synchronize here, it will automatically synchronize dutchie and elastic search in every hour.</code></h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/synchronize.js"></script>

<style scoped>
.edit-button {
    background-color: #17a00e;
}

.delete-button {
    background-color: #f41127;
}
</style>