import {authHeader} from "../../../auth";

export default {
    name: "BlogCategory",
    data() {
        return {
            categoryList: [],
            categoryName: null,
            singleCategory: []
        }
    },
    async created() {
        document.title = "JARS - Article Category";
        await this.getCategoryList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getCategoryList: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.categoryURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.categoryList = response.data.data;
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        addCategory: function() {
            if(!this.categoryName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please insert category name!"
                });
                return false;
            }
            let data = {
                categoryName: this.categoryName
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.blog.categoryURL,
                data: data,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    this.categoryName = "";
                }
                this.getCategoryList();
                document.getElementById("closeButton").click();
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        deleteCategory: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
                }).then(async(result) => {
                    if (result.isConfirmed) {
                        try {
                            let config = {
                                method: 'DELETE',
                                url: this.$serverURL + this.$api.blog.categoryURL +'/'+ id,
                                headers: authHeader()
                            }
                            await this.deleteData(config);
                            this.dataList = await this.getCategoryList(this.setURL);
                        } catch (error) {
                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        }
                    }
            })
            
        },
        setId: function(index) {
            this.singleCategory = this.categoryList[index];
        },
        updateCategory: function(id) {
            let data = {
                categoryName: this.singleCategory.categoryName
            };
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.blog.categoryURL +'/'+ id,
                data: data,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.getCategoryList();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }
}
