<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Benefits Section CMS</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Benefits Section CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <form @submit.prevent="updateCms()">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Benefits Section CMS</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-8 mb-3">
                  <div class="border border-3 p-4 rounded">
                    <div class="row">
                      <div class="col-12 mb-3">
                        <label class="form-label">Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.title"
                          class="form-control"
                          placeholder="Enter Title"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Sub Title:</label>
                        <textarea
                          type="text"
                          v-model="cmsData.subTitle"
                          class="form-control"
                          placeholder="Enter Sub Title"
                        />
                      </div>

                      <!-- <div class="col-12 mb-3">
                        <label class="form-label">Image One:</label>
                        <div class="col-12">
                          <img
                            style="position: relative; left: 11px"
                            v-if="preview.imageOne"
                            :src="preview.imageOne"
                            height="70"
                          />
                          <img
                            style="position: relative; left: 11px"
                            v-else-if="cmsData.imageOne"
                            :src="cmsData.imageOne"
                            height="70"
                          />
                        </div>
                        <input
                          class="form-control mt-3"
                          type="file"
                          ref="imageOne"
                          accept="image/*"
                          @change="handleUploadImage('imageOne','imageOne','imageOne', $event)"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Title One:</label>
                        <input
                          type="text"
                          v-model="cmsData.titleOne"
                          class="form-control"
                          placeholder="Enter Title One"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Description One:</label>
                        <textarea
                          type="text"
                          v-model="cmsData.descriptionOne"
                          class="form-control"
                          placeholder="Enter Description One"
                        />
                      </div>
                       <div class="col-12 mb-3">
                        <label class="form-label">Button One Text:</label>
                        <input
                          type="text"
                          v-model="cmsData.buttonOneText"
                          class="form-control"
                          placeholder="Enter Button One Text"
                        />
                      </div>
                       <div class="col-12 mb-3">
                        <label class="form-label">Button One Link:</label>
                        <input
                          type="text"
                          v-model="cmsData.buttonOneLink"
                          class="form-control"
                          placeholder="Enter Button One Link"
                        />
                      </div>-->

                      <!-- <div class="col-12 mb-3">
                        <label class="form-label">Image Two:</label>
                        <div class="col-12">
                          <img
                            style="position: relative; left: 11px"
                            v-if="preview.imageTwo"
                            :src="preview.imageTwo"
                            height="70"
                          />
                          <img
                            style="position: relative; left: 11px"
                            v-else-if="cmsData.imageTwo"
                            :src="cmsData.imageTwo"
                            height="70"
                          />
                        </div>
                        <input
                          class="form-control mt-3"
                          type="file"
                          ref="imageTwo"
                          accept="image/*"
                          @change="handleUploadImage('imageTwo','imageTwo','imageTwo', $event)"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Title Two:</label>
                        <input
                          type="text"
                          v-model="cmsData.titleTwo"
                          class="form-control"
                          placeholder="Enter Title Two"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Description Two:</label>
                        <textarea
                          type="text"
                          v-model="cmsData.descriptionTwo"
                          class="form-control"
                          placeholder="Enter Description Two"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Button Two Text:</label>
                        <input
                          type="text"
                          v-model="cmsData.buttonTwoText"
                          class="form-control"
                          placeholder="Enter Button Two Text"
                        />
                      </div>
                       <div class="col-12 mb-3">
                        <label class="form-label">Button Two Link:</label>
                        <input
                          type="text"
                          v-model="cmsData.buttonTwoLink"
                          class="form-control"
                          placeholder="Enter Button Two Link"
                        />
                      </div>-->

                      <!-- <div class="col-12 mb-3">
                        <label class="form-label">Image Three:</label>
                        <div class="col-12">
                          <img
                            style="position: relative; left: 11px"
                            v-if="preview.imageThree"
                            :src="preview.imageThree"
                            height="70"
                          />
                          <img
                            style="position: relative; left: 11px"
                            v-else-if="cmsData.imageThree"
                            :src="cmsData.imageThree"
                            height="70"
                          />
                        </div>
                        <input
                          class="form-control mt-3"
                          type="file"
                          ref="imageThree"
                          accept="image/*"
                          @change="handleUploadImage('imageThree','imageThree','imageThree', $event)"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Title Three:</label>
                        <input
                          type="text"
                          v-model="cmsData.titleThree"
                          class="form-control"
                          placeholder="Enter Title Three"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Description Three:</label>
                        <textarea
                          type="text"
                          v-model="cmsData.descriptionThree"
                          class="form-control"
                          placeholder="Enter Description Three"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Button Three Text:</label>
                        <input
                          type="text"
                          v-model="cmsData.buttonThreeText"
                          class="form-control"
                          placeholder="Enter Button Three Text"
                        />
                      </div>
                       <div class="col-12 mb-3">
                        <label class="form-label">Button Three Link:</label>
                        <input
                          type="text"
                          v-model="cmsData.buttonThreeLink"
                          class="form-control"
                          placeholder="Enter Button Three Link"
                        />
                      </div>-->

                      <div class="col-12 mb-3">
                        <label class="form-label">Section Button Text:</label>
                        <input
                          type="text"
                          v-model="cmsData.sectionButtonText"
                          class="form-control"
                          placeholder="Enter Section Button Text"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Section Button Link:</label>
                        <input
                          type="text"
                          v-model="cmsData.sectionButtonLink"
                          class="form-control"
                          placeholder="Enter Section Button Link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-grid col-8">
                  <button type="submit" class="btn btn-primary">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <h5 class="card-title">Benefits Section Content</h5>
            <button
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
            >Add New Content</button>
          </div>
          <hr />
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Title</th>
                  <th>Image</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in contentList" :key="index">
                  <td>{{index + 1}}</td>
                  <td>{{data.title}}</td>
                  <td>
                    <img v-if="data.image" :src="data.image" width="100" />
                    <img v-else src="/core/assets/images/default.png" width="100" />
                  </td>
                  <td>{{this.$filters.formatDate(data.createdAt)}}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#updateModal"
                        class="edit-button"
                        @click="editContent(data)"
                      >
                        <i class="bx bxs-edit text-white"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="ms-3 primary-button"
                        @click="$router.push({path:'/benefits-learn-more-section', query:{id:data.id, title:data.title}})"
                      >
                        <i class="fa-solid fa-eye text-white"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="ms-3 delete-button"
                        v-on:click="deleteContent(data.id)"
                      >
                        <i class="bx bxs-trash text-white"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Title</th>
                  <th>Image</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="addModal"
        tabindex="-1"
        aria-labelledby="addModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="addModalLabel">Add New Content</h5>
              <button
                type="button"
                id="addBtn"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form @submit.prevent="addContent">
              <div class="row m-1">
                <div class="col-12 mb-3">
                  <label class="form-label">Image:</label>
                  <div class="col-12 mb-2">
                    <input
                      class="form-control mt-3"
                      type="file"
                      ref="addImage"
                      accept="image/*"
                      @change="handleUploadImage($event)"
                    />
                  </div>
                  <div class="col-md-12" v-if="previewImage">
                    <img :src="previewImage" width="200" />
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <label class="form-label">Title:</label>
                  <input type="text" v-model="title" class="form-control" placeholder="Enter Title" />
                </div>
                <div class="col-12 mb-3">
                  <label class="form-label">Description:</label>
                  <textarea
                    type="text"
                    v-model="description"
                    class="form-control"
                    placeholder="Enter Description"
                  />
                </div>
                <div class="col-12 mb-3">
                  <label class="form-label">Button Text:</label>
                  <input
                    type="text"
                    v-model="buttonText"
                    class="form-control"
                    placeholder="Enter Button Text"
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="updateModal"
        tabindex="-1"
        aria-labelledby="updateModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="updateModalLabel">Update Content</h5>
              <button
                type="button"
                id="updateCloseBtn"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form @submit.prevent="updateContent">
              <div class="row m-1">
                <div class="col-12 mb-3">
                  <label class="form-label">Image:</label>
                  <div class="col-12 mb-2">
                    <input
                      class="form-control mt-3"
                      type="file"
                      ref="updateImage"
                      accept="image/*"
                      @change="handleUpdateImage($event)"
                    />
                  </div>
                  <div class="col-md-12">
                    <img v-if="previewUpdateImage" :src="previewUpdateImage" width="200" />
                    <img v-else-if="singleContent.image" :src="singleContent.image" width="200" />
                    <img v-else src="/core/assets/images/default.png" width="200" />
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <label class="form-label">Title:</label>
                  <input
                    type="text"
                    v-model="singleContent.title"
                    class="form-control"
                    placeholder="Enter Title"
                  />
                </div>
                <div class="col-12 mb-3">
                  <label class="form-label">Description:</label>
                  <textarea
                    type="text"
                    v-model="singleContent.description"
                    class="form-control"
                    placeholder="Enter Description"
                  />
                </div>
                <div class="col-12 mb-3">
                  <label class="form-label">Button Text:</label>
                  <input
                    type="text"
                    v-model="singleContent.buttonText"
                    class="form-control"
                    placeholder="Enter Button Text"
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/benefits-section.js"></script>

<style scoped>
.edit-button {
  background-color: #17a00e;
}

.primary-button {
  background-color: #008cff;
}

.delete-button {
  background-color: #f41127;
}
</style>