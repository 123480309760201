<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Product Details Page Meta</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">Product Details Page Meta</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Product Details Page Meta</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-md-10 col-12">
                                <div class="border border-3 p-4 rounded">
                                    <div class="mb-3">
                                        <label class="form-label">Meta Title</label>
                                        <Multiselect
                                            v-model="selectedMetaTitles"
                                            mode="tags"
                                            placeholder="select meta titles"
                                            :options="metaTitleList"
                                            :close-on-select="false"
                                            :searchable="true"
                                            :create-option="true"
                                        />
                                        <input type="text" disabled v-model="selectedTitles" class="mt-3 form-control" placeholder="meta title" />
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Meta Description</label>
                                        <Multiselect
                                            v-model="selectedMetaDescriptions"
                                            mode="tags"
                                            placeholder="select meta description"
                                            :options="metaDescriptionList"
                                            :close-on-select="false"
                                            :searchable="true"
                                            :create-option="true"
                                        />
                                        <input type="text" disabled v-model="selectedDescriptions" class="mt-3 form-control" placeholder="meta description" />
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Product Schema</label>
                                        <textarea class="form-control" v-model="productSchema" cols="30" rows="10" placeholder="product schema"></textarea>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <button @click="updatePageDetailsMeta" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/product-meta-mapping.js"></script>