export default {
    data() {
        return {
            robot: "",
            siteUrl: this.$serverURL + this.$api.cms.RobotTxt
        }
    },
    async mounted() {
        document.title = "Jars - Robot Txt CMS";
    },
    async created() {
        const robotData = await this.getCmsData(this.siteUrl);
        if(robotData?.data?.length) {
            this.robot = robotData?.data?.[0]?.robotTxt;
        }
    },
    methods: {
        async updateRobotTxt() {
            try {
                if(!this.robot) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter meta titles!"
                    });
                } else {
                    const data = {
                        robotTxt: this.robot
                    };
                    const res = await this.createUpdateCMS(this.siteUrl, data);
                    if (res.statusCode == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: res.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }

                    const robotData = await this.getCmsData(this.siteUrl);
                    if(robotData?.data?.length) {
                        this.robot = robotData?.data?.[0]?.robotTxt;
                    }
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        }
    }
}