import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.careerPageCmsURL,
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            cmsData: {},
            previewImage: "",
        }
    },
    async created() {
        document.title = "JARS - Career Page CMS";
        this.getData();
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        onImageChange: function (event) {
            this.cmsData.heroSectionBgImage = event.target.files[0];
            let input = this.$refs.bgImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        async update() {
            try {
                const formData = new FormData();
                formData.append('heroSectionBgImage', this.cmsData.heroSectionBgImage);
                formData.append('heroSectionTitleOne', this.cmsData.heroSectionTitleOne);
                formData.append('heroSectionTitleTwo', this.cmsData.heroSectionTitleTwo);
                formData.append('heroSectionDescription', this.cmsData.heroSectionDescription);
                formData.append('heroSectionBtnText', this.cmsData.heroSectionBtnText);
                formData.append('heroSectionBtnLink', this.cmsData.heroSectionBtnLink);
                formData.append('sectionTwoTitle', this.cmsData.sectionTwoTitle);
                formData.append('sectionTwoSubTitle', this.cmsData.sectionTwoSubTitle);
                formData.append('sectionTwoContentOne', this.cmsData.sectionTwoContentOne);
                formData.append('sectionTwoContentTwo', this.cmsData.sectionTwoContentTwo);
                formData.append('sectionTwoEndContent', this.cmsData.sectionTwoEndContent);
                // let data = {
                //     heroSectionTitleOne: this.cmsData.heroSectionTitleOne,
                //     heroSectionTitleTwo: this.cmsData.heroSectionTitleTwo,
                //     heroSectionDescription: this.cmsData.heroSectionDescription,
                //     heroSectionBtnText: this.cmsData.heroSectionBtnText,
                //     heroSectionBtnLink: this.cmsData.heroSectionBtnLink,
                //     sectionTwoTitle: this.cmsData.sectionTwoTitle,
                //     sectionTwoSubTitle: this.cmsData.sectionTwoSubTitle,
                //     sectionTwoContentOne: this.cmsData.sectionTwoContentOne,
                //     sectionTwoContentTwo: this.cmsData.sectionTwoContentTwo,
                //     sectionTwoEndContent: this.cmsData.sectionTwoEndContent,
                // };
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, formData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}