export default {
  data() {
    return {
      url: this.$serverURL + this.$api.cms.service.requestURL,
      cmsData: {},
      imageOne: '',
      previewImageOne: '',
      imageTwo: '',
      previewImageTwo: '',
      imageThree: '',
      previewImageThree: '',
    };
  },
  async created() {
    document.title = 'JARS - Service Type CMS';
    this.getData();
  },
  methods: {
    async getData() {
      let response = await this.getCmsData(this.url);
      if (response.statusCode == 200) {
        this.cmsData = response.data.length > 0 ? response.data[0] : {};
      } else {
        this.cmsData = {};
      }
    },
    uploadImageOne: function (event) {
      this.imageOne = event.target.files[0];
      let input = this.$refs.imageOne;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImageOne = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    uploadImageTwo: function (event) {
      this.imageTwo = event.target.files[0];
      let input = this.$refs.imageTwo;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImageTwo = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    uploadImageThree: function (event) {
      this.imageThree = event.target.files[0];
      let input = this.$refs.imageThree;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImageThree = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    async update() {
      try {
        let formData = new FormData();
        formData.append(
          'imageOne',
          this.imageOne
            ? this.imageOne
            : this.cmsData.imageOne
              ? this.cmsData.imageOne
              : ''
        );
        formData.append(
          'titleOne',
          this.cmsData.titleOne ? this.cmsData.titleOne : ''
        );
        formData.append(
          'imageTwo',
          this.imageTwo
            ? this.imageTwo
            : this.cmsData.imageTwo
              ? this.cmsData.imageTwo
              : ''
        );
        formData.append(
          'titleTwo',
          this.cmsData.titleTwo ? this.cmsData.titleTwo : ''
        );
        formData.append(
          'imageThree',
          this.imageThree
            ? this.imageThree
            : this.cmsData.imageThree
              ? this.cmsData.imageThree
              : ''
        );
        formData.append(
          'titleThree',
          this.cmsData.titleThree ? this.cmsData.titleThree : ''
        );
        this.$swal.showLoading();
        let response = await this.createUpdateCMS(this.url, formData);
        // this.$refs.imageOne.value = "";
        this.$refs.imageTwo.value = "";
        this.$refs.imageThree.value = "";
        // this.previewImageOne = "";
        this.previewImageTwo = "";
        this.previewImageThree = "";

        if (response.statusCode == 200 || response.statusCode == 201) {
          this.$swal.fire({
            toast: true,
            position: 'top-end',
            text: response.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 3000,
            animation: false,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', this.$swal.stopTimer);
              toast.addEventListener('mouseleave', this.$swal.resumeTimer);
            },
          });
        }
        await this.getData();
      } catch (error) {
        console.log('error', error)
        this.$swal.fire({
          icon: 'error',
          text: 'Something went wrong. Please try again!',
        });
      } finally {
        this.$swal.hideLoading();
      }
    },
  },
};
