<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Home Page</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Home Press Release</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body p-4">
          <h5 class="card-title">Add New Press Release</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-8">
                <div class="border border-3 p-4 rounded">
                  <div class="row mb-3">
                    <div class="col-md-6">
                      <label class="form-label">Title:</label>
                      <input type="text" v-model="title" class="form-control" />
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Category:</label>
                      <select v-model="selectedCategory" class="form-select form-control mb-3">
                        <option style="display:none" selected value>Select Category</option>
                        <option
                          class="p-1"
                          v-for="(data, index) in pressCategory"
                          :key="index"
                          :value="data.title"
                        >{{ data.title }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Description:</label>
                    <ckeditor :editor="editor" v-model="description"></ckeditor>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">
                      Image:
                      <strong>[Preferred Image Size: 1920X1280, Max Upload Limit: 1MB]</strong>
                    </label>
                    <input class="form-control" @change="onImageChange" type="file" ref="imageFile" />
                  </div>
                  <div class="mb-3">
                    <div class="col-md-3">
                      <img v-if="previewImage" :src="previewImage" width="400" />
                      <img v-else src="../../../assets/images/default.png" height="100" width="150" />
                    </div>
                  </div>
                  <div class="d-grid">
                    <button @click="savePressRelease" type="button" class="btn btn-primary">Create</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/add-press-release.js"></script>