// import { authHeader } from '../../../../../auth';

export default {
    name: "AddCareerDepartment",
    data() {
        return {
            name: '',
            logo: '',
            previewLogo: null
        }
    },
    async created() {
        document.title = "JARS - Add Career Department";
    },
    methods: {
        onLogoChange: function (event) {
            this.logo = event.target.files[0];
            let input = this.$refs.logo;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewLogo = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        validate: function() {
            if(!this.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter name!"
                });
                return false;
            }
            if(!this.logo) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please upload logo!"
                });
                return false;
            }
            return true;
        },
        save: async function() {
            if(this.validate() == true) {
                let token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append("name", this.name);
                formData.append("logo", this.logo);
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.careerDepartment.requestURL,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                    this.name = '';
                    this.logo = '';
                    this.$refs.logo.value = null;
                    this.$router.push("/career-department");
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}