<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Section Two</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Section Two CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body" style="display: flex; align-items: center;">
          <h5 style="margin: 0;">{{stateName}}</h5>
          <div
            style="display: flex; align-items: center; cursor: pointer; margin-left: auto;"
            @click="$router.push({ path: '/section-two-states'})"
          >
            <span style="margin-right: 5px;">
              <i class="bx bxs-arrow-to-left text-dark" style="font-size: 30px;"></i>
            </span>
            <h6 style="margin: 0; cursor:pointer; font-weight: 600;">Go Back</h6>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-between align-items-center">
            <h5 class="card-title mb-0 pb-0">Section Two CMS</h5>
            <!-- <div class="ms-auto">
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#addModal"
                >
                  <i class="bx bxs-plus-square"></i>Set Default
                </button>
              </div>
            </div>-->
          </div>

          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-6">
                <div class="border border-3 p-4 rounded h-100">
                  <div class="mb-3">
                    <label class="form-label">Title:</label>
                    <input type="text" v-model="cmsData.title" class="form-control" />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Sub Title:</label>
                    <input type="text" v-model="cmsData.subTitle" class="form-control" />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Button Text:</label>
                    <input type="text" v-model="cmsData.buttonText" class="form-control" />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Button Link:</label>
                    <input type="text" v-model="cmsData.buttonLink" class="form-control" />
                  </div>
                  <div class="mb-3">
                    <div class="form-check form-switch">
                      <label
                        class="form-label"
                      >[If toggle on dutchie custom section data will be loaded, otherwise admin custom data will be loaded]</label>
                      <input
                        @change="updateStatus()"
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.status"
                        id="flexSwitchCheckDefault"
                      />
                    </div>
                    <div class="form-check form-switch">
                      <label
                        class="form-label"
                      >[If there is no data for the state, then this deta will show as default for the state.]</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.isDefault"
                        id="flexSwitchCheckDefault"
                      />
                    </div>
                  </div>
                  <div class="d-grid">
                    <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
              <div class="col-lg-6" v-if="cmsData.status == true">
                <div class="border border-3 p-4 rounded h-100">
                  <div class="d-flex justify-content-center">
                    <img
                      style="width: 100%;"
                      src="../../../../../../public/core/assets/images/home-page-section-two.png"
                    />
                  </div>
                  <h6 class="text-center pt-4">
                    <b>Dutchie Custom Key: POPULAR</b>
                  </h6>
                </div>
              </div>
              <div class="col-lg-6" v-if="cmsData.status == false">
                <div class="border border-3 p-4 rounded h-100">
                  <div class="d-flex justify-content-center">
                    <img
                      style="width: 100%;"
                      src="../../../../../../public/core/assets/images/section-two-custom.png"
                    />
                  </div>
                  <h6 class="text-center pt-4">
                    <b>Admin Custom Section</b>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-between align-items-center">
            <h5 class="card-title mb-0 pb-0">Section Two Custom</h5>
            <div class="ms-auto">
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#addModal"
                >
                  <i class="bx bxs-plus-square"></i>Add New Data
                </button>
              </div>
            </div>
          </div>
          <hr />
          <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div class="ms-auto">
              <!-- Add Modal -->
              <div
                class="modal fade"
                id="addModal"
                tabindex="-1"
                aria-labelledby="addModalLabel"
                aria-hidden="true"
              >
                <form @submit.prevent="addEntity" enctype="multipart/form-data">
                  <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="addModalLabel">Add New Data</h5>
                        <button
                          type="button"
                          id="closeButton"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <label class="form-label">Title:</label>
                        <input class="form-control mb-1" type="text" v-model="title" />
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="modal-body">
                            <label class="form-label">Button Text:</label>
                            <input class="form-control mb-1" type="text" v-model="buttonText" />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="modal-body">
                            <label class="form-label">Button Link:</label>
                            <input class="form-control mb-1" type="text" v-model="buttonLink" />
                          </div>
                        </div>
                      </div>
                      <div class="modal-body">
                        <label class="form-label">Description:</label>
                        <textarea class="form-control" v-model="description" rows="3"></textarea>
                      </div>
                      <div class="modal-body">
                        <label
                          class="form-label"
                        >Image: [Preferred Image Size: 340X283, Max Upload Limit: 1MB]</label>
                        <input
                          class="form-control"
                          @change="onImageChange"
                          type="file"
                          ref="imageFile"
                        />
                      </div>
                      <div class="modal-body">
                        <div class="col-md-3">
                          <img v-if="previewImage" :src="previewImage" height="100" width="150" />
                          <img
                            v-else
                            src="../../../../../assets/images/default.png"
                            height="100"
                            width="150"
                          />
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >Close</button>
                        <button type="submit" class="btn btn-primary">Add</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <!-- Add Modal -->
              <!-- Edit Modal -->
              <div
                class="modal fade"
                id="editModal"
                tabindex="-1"
                aria-labelledby="addModalLabel"
                aria-hidden="true"
              >
                <form @submit.prevent="updateEntity">
                  <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="addModalLabel">Update Data</h5>
                        <button
                          type="button"
                          id="buttonClose"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <label class="form-label">Title:</label>
                        <input class="form-control mb-1" type="text" v-model="singleData.title" />
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="modal-body">
                            <label class="form-label">Button Text:</label>
                            <input
                              class="form-control mb-1"
                              type="text"
                              v-model="singleData.buttonText"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="modal-body">
                            <label class="form-label">Button Link:</label>
                            <input
                              class="form-control mb-1"
                              type="text"
                              v-model="singleData.buttonLink"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="modal-body">
                        <label class="form-label">Description:</label>
                        <textarea class="form-control" v-model="singleData.description" rows="3"></textarea>
                      </div>
                      <div class="modal-body">
                        <label
                          class="form-label"
                        >Image: [Preferred Image Size: 340X283, Max Upload Limit: 1MB]</label>
                        <input
                          class="form-control"
                          @change="onUpdateImageChange"
                          type="file"
                          ref="updateImageFile"
                        />
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <img
                            v-if="previewUpdateImage"
                            class="img-thumbnail"
                            :src="previewUpdateImage"
                            height="100"
                            width="150"
                          />
                          <img
                            v-else-if="singleData.image"
                            class="img-thumbnail"
                            :src="singleData.image"
                            height="100"
                            width="150"
                          />
                          <img
                            v-else
                            class="img-thumbnail"
                            src="../../../../../assets/images/default.png"
                            height="100"
                            width="150"
                          />
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >Close</button>
                        <button type="submit" class="btn btn-primary">Update</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <!-- Edit Modal -->
            </div>
          </div>
          <div class="form-body mt-4">
            <div class="row">
              <div class="table-responsive">
                <table id="example" class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Sl</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Image</th>
                      <th>Button Text</th>
                      <th>Button Link</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in dataList" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ data.title }}</td>
                      <td>{{ data.description.length > 50 ? data.description.slice(0, 50) + '...' : data.description }}</td>
                      <td>
                        <img :src="data.image" height="50" />
                      </td>
                      <td>{{ data.buttonText }}</td>
                      <td>{{ data.buttonLink }}</td>
                      <td>
                        <div class="d-flex order-actions">
                          <a
                            href="javascript:void(0);"
                            v-on:click="editEntity(data)"
                            data-bs-toggle="modal"
                            data-bs-target="#editModal"
                            class="edit-button"
                          >
                            <i class="bx bxs-edit text-white"></i>
                          </a>
                          <a
                            href="javascript:void(0);"
                            v-on:click="deleteEntity(data.id)"
                            class="ms-3 delete-button"
                          >
                            <i class="bx bxs-trash text-white"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Sl</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Image</th>
                      <th>Button Text</th>
                      <th>Button Link</th>
                      <th>Action</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/section-two-cms.js"></script>

<style scoped>
@import "../../../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>