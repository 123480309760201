<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">About Page CMS</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">About Page CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <form @submit.prevent="updateCms()">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">About Page CMS</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-12 mb-3">
                  <div class="border border-3 p-4 rounded">
                    <div class="row">
                      <div class="col-6 mb-3">
                        <label class="form-label">Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.title"
                          class="form-control"
                          placeholder="Enter title"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Sub Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.subTitle"
                          class="form-control"
                          placeholder="Enter sub title"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Section Two Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.sectionTwoTitle"
                          class="form-control"
                          placeholder="Enter section two title"
                        />
                      </div>

                      <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Description:</label>
                        <textarea
                          class="form-control"
                          rows="3"
                          v-model="cmsData.description"
                          placeholder="Enter description"
                        ></textarea>
                      </div>

                      <div class="mb-3">
                        <label class="form-label">Address:</label>
                        <GMapAutocomplete
                          @place_changed="handleAddress"
                          :value="cmsData.address"
                          :class="'form-control'"
                           v-on:input="cmsData.address = $event.target.value"
                        ></GMapAutocomplete>
                      </div>

                      <div class="col-6 mb-3">
                        <label class="form-label">Email:</label>
                        <input
                          type="email"
                          v-model="cmsData.email"
                          class="form-control"
                          placeholder="Enter email"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label class="form-label">Contact No:</label>
                        <input
                          type="tel"
                          v-model="cmsData.contactNumber"
                          class="form-control"
                          placeholder="Enter contact no"
                        />
                      </div>

                      <div class="col-6 mb-3">
                        <label for="inputProductTitle" class="form-label">
                          Image One:
                          <strong>
                            [Preferred Image Size: 334X428, Max Upload Limit:
                            1MB]
                          </strong>
                        </label>
                        <img
                          style="position: relative; left: 11px"
                          v-if="preview.imageOne"
                          :src="preview.imageOne"
                          height="70"
                          width="100"
                        />
                        <img
                          style="position: relative; left: 11px"
                          v-else-if="cmsData.imageOne"
                          :src="cmsData.imageOne"
                          height="70"
                          width="100"
                        />
                        <input
                          class="form-control mt-3"
                          type="file"
                          ref="backgroundImage1"
                          accept="image/*"
                          @change="handleUploadImage('imageOne','imageOne','backgroundImage1', $event)"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label for="inputProductTitle" class="form-label">
                          Image Two:
                          <strong>
                            [Preferred Image Size: 283X205, Max Upload Limit:
                            1MB]
                          </strong>
                        </label>
                        <img
                          style="position: relative; left: 11px"
                          v-if="preview.imageTwo"
                          :src="preview.imageTwo"
                          height="70"
                          width="100"
                        />
                        <img
                          style="position: relative; left: 11px"
                          v-else-if="cmsData.imageTwo"
                          :src="cmsData.imageTwo"
                          height="70"
                          width="100"
                        />
                        <input
                          class="form-control mt-3"
                          type="file"
                          ref="backgroundImage2"
                          accept="image/*"
                         @change="handleUploadImage('imageTwo','imageTwo','backgroundImage2', $event)"
                        />
                      </div>
                      <div class="col-6 mb-3">
                        <label for="inputProductTitle" class="form-label">
                          Image Three:
                          <strong>
                            [Preferred Image Size: 279X202, Max Upload Limit:
                            1MB]
                          </strong>
                        </label>
                        <img
                          style="position: relative; left: 11px"
                          v-if="preview.imageThree"
                          :src="preview.imageThree"
                          height="70"
                          width="100"
                        />
                        <img
                          style="position: relative; left: 11px"
                          v-else-if="cmsData.imageThree"
                          :src="cmsData.imageThree"
                          height="70"
                          width="100"
                        />
                        <input
                          class="form-control mt-3"
                          type="file"
                          ref="backgroundImage3"
                          accept="image/*"
                          @change="handleUploadImage('imageThree','imageThree','backgroundImage3', $event)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-grid">
                  <button type="submit" class="btn btn-primary">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src="../js/about-page.js"></script>