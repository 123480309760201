import { authHeader } from "../../../auth";

export default {
    name: "BlogEditPost",
    data() {
        return {
            singleContent: {
                image: '',
                title: '',
                subTitle: '',
                termsAndConditionTitle: ''
            },
            benefitsId: "",
            previewImage: '',
        }
    },
    async created() {
        document.title = "JARS - Benefits";
        this.benefitsId = this.$route.params.id;
        await this.getBenefitsData();
        var scripts = [
            "/core/assets/js/jquery.min.js",
            "/core/assets/plugins/simplebar/js/simplebar.min.js",
            "/core/assets/plugins/metismenu/js/metisMenu.min.js",
            "./core/assets/plugins/perfect-scrollbar/js/perfect-scrollbar.js",
            "/core/assets/plugins/smart-wizard/js/jquery.smartWizard.min.js",
            "/core/assets/js/form-wizard.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        onImageChange(event) {
            this.singleContent.image = event.target.files[0];
            let input = this.$refs.imageFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        getBenefitsData: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.cms.benefitsModal + '/' + this.benefitsId,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.benefitsId = response.data.data[0].id;
                    this.singleContent.image = response.data.data[0].image;
                    this.singleContent.title = response.data.data[0].title;
                    this.singleContent.subTitle = response.data.data[0].subTitle;
                    this.singleContent.termsAndConditionTitle = response.data.data[0].termsAndConditionTitle;
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },

        validate: function () {
            if (!this.singleContent.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title!"
                });
                return false;
            }
            if (!this.singleContent.subTitle) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter sub title!"
                });
                return false;
            }
            if (!this.singleContent.termsAndConditionTitle) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter terms and condition title!"
                });
                return false;
            }
            if (!this.singleContent.image) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please upload image!"
                });
                return false;
            }
            return true;
        },
        updateBenefits: async function () {
            if (this.validate() == true) {
                let token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append("image", this.singleContent.image);
                formData.append("title", this.singleContent.title);
                formData.append("subTitle", this.singleContent.subTitle);
                formData.append("termsAndConditionTitle", this.singleContent.termsAndConditionTitle);
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.cms.benefitsModal + '/' + this.benefitsId,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                }).catch(error => {
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!"
                    });
                });
                this.$router.push('/benefits-modal');
            }
        }

    }
}