export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.footerCmsURL,
            cmsData: {}
        }
    },
    async created() {
        document.title = "JARS - Footer CMS";
        this.getData();
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        async update() {
            try {
                let data = {
                    copyrightText: this.cmsData.copyrightText,
                    title: this.cmsData.title,
                    subTitle: this.cmsData.subTitle,
                    description: this.cmsData.description,
                    buttonOneText: this.cmsData.buttonOneText,
                    buttonTwoText: this.cmsData.buttonTwoText,
                    buttonOneLink: this.cmsData.buttonOneLink,
                    buttonTwoLink: this.cmsData.buttonTwoLink,
                    sectionTwoTitle: this.cmsData.sectionTwoTitle,
                    sectionTwoSubTitle: this.cmsData.sectionTwoSubTitle,
                    sectionTwoButtonText: this.cmsData.sectionTwoButtonText,
                    sectionTwoButtonLink: this.cmsData.sectionTwoButtonLink
                };
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}