export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.aboutBenifitLearnMore,

            benefitsId: this.$route.query.id,
            benefitsTitle: this.$route.query.title,

            contentList: [],

            image: "",
            titile: "",
            description: "",

            singleContent: {
                image: "",
                titile: "",
                description: "",
            },

            previewImage: "",
            previewUpdateImage: "",

        }
    },
    async created() {
        document.title = "Jars - Benifits Learn More";
        this.getLearnList();
    },
    methods: {
        getLearnList: async function () {
            try {
                this.contentList = [];
                let token = localStorage.getItem('token');
                let config = {
                    method: "GET",
                    url: this.url + "/" + this.benefitsId,
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                }
                await this.$axios(config).then((response) => {
                    if (response.data.statusCode == 200) {
                        this.contentList = response.data.data;
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            }
        },
        handleUploadImage(event) {
            this.image = event.target.files[0];
            let input = this.$refs.addImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        editContent(data) {
            this.singleContent = data;
        },
        handleUpdateImage(event) {
            this.singleContent.image = event.target.files[0];
            let input = this.$refs.updateImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewUpdateImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async addContent() {
            try {
                let token = localStorage.getItem('token');
                let formData = new FormData();
                formData.append('benefitsId', this.benefitsId);
                formData.append('benefitsTitle', this.benefitsTitle);
                formData.append('image', this.image);
                formData.append('title', this.title);
                formData.append('description', this.description);
                this.$swal.showLoading();
                let config = {
                    method: "POST",
                    url: this.url,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                };
                let response = await this.postData(config);
                if (response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });

                }
                this.title = "";
                this.image = "";
                this.description = "";
                this.previewImage = "";
                this.$refs.addImage.value = null;
                await this.getLearnList();
                document.getElementById('addBtn').click();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        },
        async updateContent() {
            try {
                let token = localStorage.getItem('token');
                let formData = new FormData();
                formData.append('benefitsId', this.benefitsId);
                formData.append('benefitsTitle', this.benefitsTitle);
                formData.append('image', this.singleContent.image);
                formData.append('title', this.singleContent.title);
                formData.append('description', this.singleContent.description);
                this.$swal.showLoading();
                let config = {
                    method: "PATCH",
                    url: this.url + '/' + this.singleContent.id,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                };
                let response = await this.updateData(config);
                if (response.statusCode == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.singleContent = {
                    image: "",
                    title: "",
                    description: "",
                }
                this.previewUpdateImage = "";
                this.$refs.updateImage.value = null;
                await this.getLearnList();
                document.getElementById('updateCloseBtn').click();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        },
        deleteContent: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let token = localStorage.getItem('token');
                        let config = {
                            method: 'DELETE',
                            url: this.url + '/' + id,
                            headers: {
                                "Authorization": "Bearer " + token
                            }
                        }
                        await this.deleteData(config);
                        await this.getData();
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                }
            })

        }
    }
}