<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Career Department Page</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">Career Department Page</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body p-4">
                    <h5 class="card-title">Add New Career Department</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="border border-3 p-4 rounded">
                                    <div class="mb-3">
                                        <label class="form-label">Name:</label>
                                        <input type="text" v-model="name" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Logo: [Preferred Logo Size: 71X71, Max Upload Limit: 1MB]</label>
                                        <input class="form-control" @change="onLogoChange" type="file" ref="logo">
                                    </div>
                                    <div class="mb-3">
                                        <div class="col-md-3">
                                            <img v-if="previewLogo" :src="previewLogo" height="100"/>
                                            <img v-else src="../../../assets/images/default.png" style="width: 100px;"/>
                                        </div>
                                    </div>
                                    <div class="d-grid">
                                        <button v-on:click="save" type="button" class="btn btn-primary">Create</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/add-career-department.js"></script>