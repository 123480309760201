<template>
  <div class="page-wrapper">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Home Page</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Home Page Slider</li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              data-bs-toggle="modal"
              data-bs-target="#addModal"
              type="button"
              class="btn btn-primary"
            >
              <i class="bx bxs-plus-square"></i>Add New
            </button>
          </div>
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Home Sliders Table</h6>
      <hr />
      <div class="card">
        <div class="card-body" style="display: flex; align-items: center;">
          <h5 style="margin: 0;">{{stateName}}</h5>
          <div
            style="display: flex; align-items: center; cursor: pointer; margin-left: auto;"
            @click="$router.push({ path: '/home-slider-states'})"
          >
            <span style="margin-right: 5px;">
              <i class="bx bxs-arrow-to-left text-dark" style="font-size: 30px;"></i>
            </span>
            <h6 style="margin: 0; cursor:pointer; font-weight: 600;">Go Back</h6>
          </div>
        </div>
      </div>
      <div
        class="page-breadcrumb d-none d-sm-flex align-items-center mb-3"
        v-if="dataList.length>0"
      >
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              @click="handleSetDefault"
              :disabled="isDefault"
            >
              <i class="bx bxs-plus-square"></i>Set Default
            </button>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Title One</th>
                  <th>Title Two</th>
                  <th>Description</th>
                  <th>Button Text</th>
                  <th>Button Link</th>
                  <th>Image</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ (row.titleOne && row.titleOne.length > 25) ? row.titleOne.slice(0, 25) + '...' : row.titleOne }}</td>
                  <td>{{ (row.titleTwo && row.titleTwo.length > 25) ? row.titleTwo.slice(0, 25) + '...' : row.titleTwo }}</td>
                  <td>{{ row.description.length > 50 ? row.description.slice(0, 50) + '...' : row.description }}</td>
                  <td>{{ row.buttonText }}</td>
                  <td>{{ row.buttonLink }}</td>
                  <td>
                    <img :src="row.image" height="50" />
                  </td>
                  <td>{{ $filters.formatDate(row.createdAt) }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a
                        href="javascript:void(0);"
                        v-on:click="editContent(row)"
                        data-bs-toggle="modal"
                        data-bs-target="#editModal"
                        class="edit-button"
                      >
                        <i class="bx bxs-edit"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        v-on:click="deleteContent(row['id'])"
                        class="ms-3 delete-button"
                      >
                        <i class="bx bxs-trash"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Title One</th>
                  <th>Title Two</th>
                  <th>Description</th>
                  <th>Button Text</th>
                  <th>Button Link</th>
                  <th>Image</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <!-- Add Modal -->
      <div
        class="modal fade"
        id="addModal"
        tabindex="-1"
        aria-labelledby="addModalLabel"
        aria-hidden="true"
      >
        <form @submit.prevent="save">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="addModalLabel">Add Content</h5>
                <button
                  type="button"
                  id="addCloseButton"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="border border-3 p-4 rounded">
                <div class="row mb-3">
                  <div class="col-sm-6">
                    <label class="form-label">Title One:</label>
                    <input type="text" v-model="titleOne" class="form-control" />
                  </div>
                  <div class="col-sm-6">
                    <label class="form-label">Title Two:</label>
                    <input type="text" v-model="titleTwo" class="form-control" />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="input-group">
                      <LvColorpicker
                        label="Section One Title Font Color:"
                        v-model="titleOneColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                        @click="addCss"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group">
                      <LvColorpicker
                        label="Section Two Title Font Color:"
                        v-model="titleTwoColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                        @click="addCss"
                      />
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <label class="form-label">Description:</label>
                  <textarea class="form-control" v-model="description" rows="3"></textarea>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-6">
                    <label class="form-label">Button Text:</label>
                    <input type="text" v-model="buttonText" class="form-control" />
                  </div>
                  <div class="col-sm-6">
                    <label class="form-label">Button Link:</label>
                    <input type="text" v-model="buttonLink" class="form-control" />
                  </div>
                </div>
                <div class="mb-3">
                  <label
                    class="form-label"
                  >Image: [Preferred Image Size: 1366X587, Max Upload Limit: 1MB]</label>
                  <input class="form-control" @change="onImageChange" type="file" ref="imageFile" />
                </div>
                <div class="mb-3">
                  <div class="col-md-3">
                    <img v-if="previewImage" :src="previewImage" width="150" />
                    <img v-else src="../../../../../assets/images/default.png" width="150" />
                  </div>
                </div>
                <div class="d-grid">
                  <button type="submit" class="btn btn-primary">Create</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- Add Modal -->
      <!-- Edit Modal -->
      <div
        class="modal fade"
        id="editModal"
        tabindex="-1"
        aria-labelledby="addModalLabel"
        aria-hidden="true"
      >
        <form @submit.prevent="update">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="addModalLabel">Update Content</h5>
                <button
                  type="button"
                  id="editCloseButton"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="border border-3 p-4 rounded">
                <div class="row mb-3">
                  <div class="col-sm-6">
                    <label class="form-label">Title One:</label>
                    <input type="text" v-model="singleContent.titleOne" class="form-control" />
                  </div>
                  <div class="col-sm-6">
                    <label class="form-label">Title Two:</label>
                    <input type="text" v-model="singleContent.titleTwo" class="form-control" />
                  </div>
                </div>
                <div class="row mb-3" v-if="dataLoaded">
                  <div class="col-md-6">
                    <div class="input-group">
                      <LvColorpicker
                        label="Section One Title Font Color:"
                        v-model="singleContent.titleOneColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                        @click="addCss"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group">
                      <LvColorpicker
                        label="Section Two Title Font Color:"
                        v-model="singleContent.titleTwoColor"
                        :clearable="true"
                        :bottomBar="true"
                        :colors="colors"
                        @click="addCss"
                      />
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <label class="form-label">Description:</label>
                  <textarea class="form-control" v-model="singleContent.description" rows="3"></textarea>
                </div>
                <div class="row mb-3">
                  <div class="col-sm-6">
                    <label class="form-label">Button Text:</label>
                    <input type="text" v-model="singleContent.buttonText" class="form-control" />
                  </div>
                  <div class="col-sm-6">
                    <label class="form-label">Button Link:</label>
                    <input type="text" v-model="singleContent.buttonLink" class="form-control" />
                  </div>
                </div>
                <div class="mb-3">
                  <label
                    class="form-label"
                  >Image: [Preferred Image Size: 1366X587, Max Upload Limit: 1MB]</label>
                  <input
                    class="form-control"
                    @change="onImageUpdateChange"
                    type="file"
                    ref="imagUpdateeFile"
                  />
                </div>
                <div class="mb-3">
                  <div class="col-md-3">
                    <img v-if="previewUpdateImage" :src="previewUpdateImage" width="150" />
                    <img v-else-if="singleContent.image" :src="singleContent.image" width="150" />
                    <img v-else src="../../../../../assets/images/default.png" width="150" />
                  </div>
                </div>
                <div class="d-grid">
                  <button type="submit" class="btn btn-primary">Update</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- Edit Modal -->
    </div>
  </div>
</template>
  
<style scoped>
@import "../../../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
.lv-component{
    z-index: 2000 !important;
}
</style>

<script src="../js/home-slider.js"></script>