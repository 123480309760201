
export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.pageTitleCMS,
            cmsData: {},
        }
    },
    async created() {
        document.title = "JARS - Site Page Title CMS";
        this.getData();

    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        async updateCms() {
            try {
                let data = {
                    'loginPageTitle': this.cmsData.loginPageTitle,
                    'signupPageTitle': this.cmsData.signupPageTitle,
                    'homePageTitle': this.cmsData.homePageTitle,
                    'shopPagetitle': this.cmsData.shopPagetitle,
                    'productDetailsPageTitle': this.cmsData.productDetailsPageTitle,
                    'cartPageTitle': this.cmsData.cartPageTitle,
                    'dailyDealsPageTitle': this.cmsData.dailyDealsPageTitle,
                    'jarsPlusPageTitle': this.cmsData.jarsPlusPageTitle,
                    'aboutUsPageTitle': this.cmsData.aboutUsPageTitle,
                    'articlesPageTitle': this.cmsData.articlesPageTitle,
                    'articleDetailsPageTitle': this.cmsData.articleDetailsPageTitle,
                    'pressReleasePageTitle': this.cmsData.pressReleasePageTitle,
                    'pressReleaseDetailsPageTitle': this.cmsData.pressReleaseDetailsPageTitle,
                    'contactUsPageTitle': this.cmsData.contactUsPageTitle,
                    'faqPageTitle': this.cmsData.faqPageTitle,
                    'careerPageTitle': this.cmsData.careerPageTitle,
                    'careerDepartmentPageTitle': this.cmsData.careerDepartmentPageTitle,
                    'careerDetailsPageTitle': this.cmsData.careerDetailsPageTitle,
                    'cookiesSettingsPageTitle': this.cmsData.cookiesSettingsPageTitle,
                    'privacyPageTitle': this.cmsData.privacyPageTitle,
                    'termsPageTitle': this.cmsData.termsPageTitle,
                    'profilePageTitle': this.cmsData.profilePageTitle,
                    'successPageTitle': this.cmsData.successPageTitle,
                    'storeDetailsPageTitle': this.cmsData.storeDetailsPageTitle,
                    'retailerPageTitle': this.cmsData.retailerPageTitle,
                    'orderPageTitle': this.cmsData.orderPageTitle,
                    'myItemsPageTitle': this.cmsData.myItemsPageTitle,
                    'getDirectionPageTitle': this.cmsData.getDirectionPageTitle,
                    'forgotPasswordPageTitle': this.cmsData.forgotPasswordPageTitle,
                    'reviewPageTitle': this.cmsData.reviewPageTitle,
                    'resetPasswordPageTitle': this.cmsData.resetPasswordPageTitle,
                    'invoicePageTitle': this.cmsData.invoicePageTitle,
                    'categoryPageTitle': this.cmsData.categoryPageTitle,
                    'ageVerificationPageTitle': this.cmsData.ageVerificationPageTitle,

                };
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}