<template>
    <div class="page-wrapper">
        <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Category</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/home">
                                    <i class="bx bx-home-alt"></i>
                                </router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Category</li>
                        </ol>
                    </nav>
                </div>
                <div class="ms-auto">
                    <div class="btn-group"></div>
                </div>
            </div>
            <h6 class="mb-0 text-uppercase">Category List</h6>
            <hr />
            <div class="card">
                <div class="p-2 mt-2" style="margin-right:5px">
                    <div style="float:right">
                        <input type="text" class="form-control" placeholder="Search category" v-model="searchKey" />
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Name</th>
                                    <th>Icon</th>
                                    <th>Image</th>
                                    <th>URL Slug</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <draggable
                                v-model="tempDataList"
                                tag="tbody"
                                item-key="data.id"
                                @update="onDragUpdate"
                            >
                                <template #item="{ element: row }">
                                <tr :class="searchKey ? 'disable-row' : ''">
                                    <!-- <td>{{ index + 1 }}</td> -->
                                    <td>{{ row.position }}</td>
                                    <td>{{ row.categoryName }}</td>
                                    <td>
                                        <img :src="row.image" height="50" />
                                    </td>
                                    <td>
                                        <img :src="row.featuredImage" height="80" />
                                    </td>
                                    <td>{{ row.urlSlug }}</td>
                                    <td>{{ $filters.formatDate(row.createdAt) }}</td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#exampleModal" v-on:click="editData(row)" class="edit-button">
                                                <i class="bx bxs-edit"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                </template>
                            </draggable>
                            <tfoot>
                                <tr>
                                    <th>Sl</th>
                                    <th>Name</th>
                                    <th>Icon</th>
                                    <th>Image</th>
                                    <th>URL Slug</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Edit Category</h5>
                        <button type="button" class="btn-close" id="close-btn" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Category Name:</label>
                            <input type="text" disabled v-model="singleData.categoryName" class="form-control"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Icon: [Preferred Icon Size: 25X25, Max Upload Limit: 1MB]</label>
                            <input class="form-control" @change="onIconChange" type="file" ref="icon"/>
                        </div>
                        <div class="mb-3">
                            <div class="col-md-12">
                                <img v-if="previewIcon" class="img-thumbnail" :src="previewIcon" style="height: 100px;" />
                                <img v-else-if="singleData.image" class="img-thumbnail" :src="singleData.image" style="height: 100px;" />
                                <img v-else class="img-thumbnail" src="../../../assets/images/default.png" style="height: 100px;" />
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Image: [Preferred Image Size: 510X500, Max Upload Limit: 1MB]</label>
                            <input class="form-control" @change="onImageChange" type="file" ref="image"/>
                        </div>
                        <div class="mb-3">
                            <div class="col-md-12">
                                <img v-if="previewImage" class="img-thumbnail" :src="previewImage" style="height: 150px;"/>
                                <img v-else-if="singleData.featuredImage" class="img-thumbnail" :src="singleData.featuredImage" style="height: 150px;"/>
                                <img v-else class="img-thumbnail" src="../../../assets/images/default.png"/>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Url Slug:</label>
                            <input type="text" disabled v-model="singleData.urlSlug" class="form-control"/>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" @click="update">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<style scoped>
@import "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
    background-color: #17a00e;
}

.delete-button {
    background-color: #f41127;
}
</style>

<script src="../js/category.js"></script>