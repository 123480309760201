import { authHeader } from '../../../../auth';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { states } from '../../../../../config/states-list';
export default {
    name: "EditCareer",
    data() {
        return {
            editData: [],
            jobType: '',
            title: '',
            location: '',
            city: '',
            selectedStore: '',
            state: '',
            shortDescription: '',
            description: '',
            salary: '',
            careerId: '',
            editor: ClassicEditor,
            careerDepartment: "",
            department: {},
            storeList: [],
            stateList: [],
            allStates: states,
            jobTypeList:['Full TIme', 'Part Time', 'Contractual'],
        }
    },
    async created() {
        document.title = "JARS - Career";
        await this.getStoreDataList();
        if (this.$route.params.id != undefined) {
            this.careerId = this.$route.params.id;
        }
        await this.getCareerData(this.careerId);
    },

    methods: {
        // getCareerDepartment: async function() {
        //     let config = {
        //         method: "GET",
        //         url: this.$serverURL + this.$api.careerDepartment.requestURL,
        //         headers: authHeader()
        //     };
        //     await this.$axios(config).then((response) => {
        //         if (response.status == 200) {
        //             this.careerDepartment = response.data.data;
        //         }
        //     }).catch(error => {
        //         console.log(error);
        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Something went wrong. Please try again!"
        //         });
        //     });
        // },
        getStoreDataList: async function () {
            try {
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.store.requestURL
                }
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.storeList = response.data;
                        let tempStateList = []
                        tempStateList = response.data.map((el) => {
                            const tempstate = this.allStates.filter(state => (state.code == el.addressObject.state));
                            return tempstate[0];
                        });
                        this.stateList = [...new Set(tempStateList)];

                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        getCareerData: async function (id) {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.cms.careerURL + '/' + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.editData = response.data.data[0];
                    this.jobType = this.editData.jobType;
                    this.title = this.editData.title;
                    this.location = this.editData.location;
                    this.city = this.editData.city;
                    this.shortDescription = this.editData.shortDescription;
                    this.description = this.editData.description;
                    this.salary = this.editData.salary;
                    if (this.editData.storeId) {
                        const tempStoreList = this.storeList.filter(el => (el.id == this.editData.storeId));
                        this.selectedStore = tempStoreList[0];
                    }
                    const tempState = this.stateList.filter(el => (el.name == this.editData.state));
                    this.state = tempState[0];
                    // this.department = {
                    //     id: this.editData.departmentId,
                    //     name: this.editData.departmentName
                    // };
                }
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        handleAddress(data) {
            this.location = data.address;
            this.city = data.addressObject.city;
            const tempState = this.stateList.filter(el => (el.code == data.addressObject.state));
            this.state = tempState[0];
        },
        update: async function () {
            let token = localStorage.getItem('token');
            const formData = {
                storeId: this.selectedStore.id,
                storeName: this.selectedStore.name,
                city: this.city,
                state: this.state.name,
                jobType: this.jobType,
                title: this.title,
                location: this.location,
                shortDescription: this.shortDescription,
                description: this.description,
                salary: this.salary,
            }
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.cms.careerURL + '/' + this.careerId,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token
                }
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.jobType = '';
                this.title = '';
                this.location = '';
                this.description = '';
                this.salary = '';
                this.$router.push("/career");
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}