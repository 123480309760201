<template>
    <div class="page-wrapper">
        <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Article Category</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">
                                Article Category
                            </li>
                        </ol>
                    </nav>
                </div>
                <div class="ms-auto">
                    <div class="btn-group">
                        <button :disabled="navList.length > 3" type="button" class="btn btn-primary" @click="$router.push('/add-article-category')">
                            <i class="bx bxs-plus-square"></i>Add New Article Category
                        </button>
                    </div>
                </div>
            </div>
            <h6 class="mb-0 text-uppercase">Article Category</h6> <span>*Only four categories are allowed on the list. If you want to add a new one, you need to delete at least one item!</span>
            <hr />
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Title</th>
                                    <th>Is Show Nav</th>
                                    <th>Image</th>
                                    <th>Hover Image</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, index) in navList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ row.title.length > 25 ? row.title.slice(0, 25) + '...' : row.title }}</td>
                                    <td>{{ row.isShowNav ? 'Active' : 'Inactive' }}</td>
                                    <td v-if="row.imageOne != null">
                                        <img :src="row.imageOne" width="30" />
                                    </td>
                                    <td v-if="row.imageTwo != null">
                                        <img :src="row.imageTwo" width="30" />
                                    </td>
                                    <td>{{ $filters.formatDateTime(row['createdAt']) }}</td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <router-link :to="{ name: 'EditArticleCategory', params: { id: row.id } }" class="edit-button"><i class="bx bxs-edit text-white"></i></router-link>
                                            <a href="javascript:void(0);" v-on:click="deleteNav(row.id)" class="ms-3 delete-button"><i class="bx bxs-trash text-white"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                     <th>Sl</th>
                                    <th>Title</th>
                                    <th>Is Show Nav</th>
                                    <th>Image</th>
                                    <th>Hover Image</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
    background-color: #17a00e;
}

.delete-button {
    background-color: #f41127;
}</style>

<script src="../js/article-category.js"></script>