import { authHeader } from '../../../../../auth';
import LvColorpicker from 'lightvue/color-picker';
export default {
    name: "HomeSlider",
    components: {
        LvColorpicker
    },
    data() {
        return {
            stateCode: this.$route.query.stateCode,
            stateName: this.$route.query.stateName,
            dataList: [],
            setURL: this.$serverURL + this.$api.cms.home.homeSliderURL,

            titleOne: '',
            titleTwo: '',
            titleOneColor: '',
            titleTwoColor: '',
            description: '',
            buttonText: '',
            buttonLink: '',
            imageFile: '',

            singleContent: {
                titleOne: '',
                titleTwo: '',
                titleOneColor: '',
                titleTwoColor: '',
                description: '',
                buttonText: '',
                buttonLink: '',
                imageFile: '',
            },
            previewImage: null,
            previewUpdateImage: null,
            dataLoaded: false,
            colors: [
                "#E91E63",
                "#F44336",
                "#9C27B0",
                "#673AB7",
                "#3F51B5",
                "#2196F3",
                "#03A9F4",
                "#00BCD4",
                "#009688",
                "#4CAF50",
                "#8BC34A",
                "#CDDC39",
                "#FFEB3B",
                "#FFC107",
                "#FF9800",
                "#795548"
            ]
        }
    },
    async created() {
        document.title = "JARS - Home Slider";
        this.dataList = await this.getDataList(this.setURL + '?stateCode=' + this.stateCode);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },

    computed: {
        isDefault() {
            let newArr = this.dataList.filter((el) => (el.isDefault == true));
            return (newArr.length == this.dataList.length) ? true : false;
        },
    },


    methods: {
        onImageChange: function (event) {
            this.imageFile = event.target.files[0];
            let input = this.$refs.imageFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        validate: function () {
            if (!this.titleOne) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title one!"
                });
                return false;
            }
            if (!this.titleTwo) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title two!"
                });
                return false;
            }
            if (!this.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter description!"
                });
                return false;
            }
            if (!this.buttonText) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter button text!"
                });
                return false;
            }
            if (!this.buttonLink) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter button link!"
                });
                return false;
            }
            if (!this.imageFile) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please upload image!"
                });
                return false;
            }
            return true;
        },
        save: async function () {
            if (this.validate() == true) {
                let token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append("stateCode", this.stateCode);
                formData.append("stateName", this.stateName);
                formData.append("titleOne", this.titleOne);
                formData.append("titleTwo", this.titleTwo);
                formData.append("titleOneColor", this.titleOneColor);
                formData.append("titleTwoColor", this.titleTwoColor);
                formData.append("description", this.description);
                formData.append("buttonText", this.buttonText);
                formData.append("buttonLink", this.buttonLink);
                formData.append("image", this.imageFile);
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.cms.home.homeSliderURL,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                    this.titleOne = '';
                    this.titleTwo = '';
                    this.titleOneColor = null;
                    this.titleTwoColor = null;
                    this.description = '';
                    this.buttonText = '';
                    this.buttonLink = '';
                    this.imageFile = '';
                    this.previewImage = '';
                    this.$refs.imageFile.value = null;
                    this.dataList = await this.getDataList(this.setURL + '?stateCode=' + this.stateCode);
                    document.getElementById('addCloseButton').click();
                    let element = document.getElementsByClassName('lv-colorpicker__colorblock');
                    element.forEach(el => {
                        el.style.backgroundColor = '';
                    })
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
        async editContent(data) {
            this.dataLoaded = false;
            this.singleContent = {
                titleOne: '',
                titleTwo: '',
                titleOneColor: "",
                titleTwoColor: "",
                description: '',
                buttonText: '',
                buttonLink: '',
                imageFile: '',
            };
            this.singleContent = await data;
            this.singleContent.imageFile = data.image;
            
            // setTimeout(() => {
            //     let element = document.getElementsByClassName('lv-colorpicker__colorblock');
            //     element[0].style.backgroundColor = this.singleContent.titleOneColor;
            //     element[1].style.backgroundColor = this.singleContent.titleTwoColor;
            // }, 2000);
            this.dataLoaded = true;
        },
        onImageUpdateChange(event) {
            this.singleContent.imageFile = event.target.files[0];
            let input = this.$refs.imagUpdateeFile;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewUpdateImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        update: async function () {
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("stateCode", this.stateCode);
            formData.append("stateName", this.stateName);
            formData.append("titleOne", this.singleContent.titleOne);
            formData.append("titleTwo", this.singleContent.titleTwo);
            formData.append("titleOneColor", this.singleContent.titleOneColor ? this.singleContent.titleOneColor : '#212529');
            formData.append("titleTwoColor", this.singleContent.titleTwoColor ? this.singleContent.titleTwoColor : '#212529');
            formData.append("description", this.singleContent.description);
            formData.append("buttonText", this.singleContent.buttonText);
            formData.append("buttonLink", this.singleContent.buttonLink);
            formData.append("image", this.singleContent.imageFile);
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.cms.home.homeSliderURL + '/' + this.singleContent.id,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.singleContent = {
                    titleOne: '',
                    titleTwo: '',
                    description: '',
                    buttonText: '',
                    buttonLink: '',
                    imageFile: '',
                }
                this.previewUpdateImage = "";
                this.$refs.imagUpdateeFile.value = null;
                this.dataList = await this.getDataList(this.setURL + '?stateCode=' + this.stateCode);
                document.getElementById('editCloseButton').click();
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        async handleSetDefault() {
            let token = localStorage.getItem('token');
            let data = {
                stateCode: this.stateCode
            }
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.homeSlider.setDefault,
                data: data,
                headers: {
                    "Authorization": "Bearer " + token,
                }
            };
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.dataList = await this.getDataList(this.setURL + '?stateCode=' + this.stateCode);
            }).catch(error => {
                console.log(error);
            });
        },
        addCss() {
            document.querySelector(".lv-overlaypanel").style.zIndex = 20000;
        },
        deleteContent: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.setURL + '/' + id,
                            headers: authHeader()
                        }
                        await this.deleteData(config);
                        this.dataList = await this.getDataList(this.setURL + '?stateCode=' + this.stateCode);
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                }
            })

        }
    }
}