<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i class="bx bx-home-alt"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Benefits Learn More List</li>
          </ol>
        </nav>
      </div>
      <h6 class="mb-2 text-uppercase">Benefits Learn More List</h6>
      <div class="card">
        <div class="card-body" style="display: flex; align-items: center;">
          <h5 style="margin: 0;">{{benefitsTitle}}</h5>
          <div
            style="display: flex; align-items: center; cursor: pointer; margin-left: auto;"
            @click="$router.push({ path: '/benefits-section-cms'})"
          >
            <span style="margin-right: 5px;">
              <i class="bx bxs-arrow-to-left text-dark" style="font-size: 30px;"></i>
            </span>
            <h6 style="margin: 0; cursor:pointer; font-weight: 600;">Go Back</h6>
          </div>
        </div>
      </div>
      <hr />

      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <h5 class="card-title">Benefits Learn More List</h5>
            <button
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
            >Add New Content</button>
          </div>
          <hr />
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Title</th>
                  <th>Image</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in contentList" :key="index">
                  <td>{{index + 1}}</td>
                  <td>{{data.title}}</td>
                  <td>
                    <img v-if="data.image" :src="data.image" width="100" />
                    <img v-else src="/core/assets/images/default.png" width="100" />
                  </td>
                  <td>{{this.$filters.formatDate(data.createdAt)}}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#updateModal"
                        class="edit-button"
                        @click="editContent(data)"
                      >
                        <i class="bx bxs-edit text-white"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="ms-3 primary-button"
                        @click="$router.push({path:'/benefits-learn-more-section', query:{id:data.id, name:data.title}})"
                      >
                        <i class="fa-solid fa-eye text-white"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="ms-3 delete-button"
                        v-on:click="deleteContent(data.id)"
                      >
                        <i class="bx bxs-trash text-white"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Title</th>
                  <th>Image</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="addModal"
        tabindex="-1"
        aria-labelledby="addModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="addModalLabel">Add New Content</h5>
              <button
                type="button"
                id="addBtn"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form @submit.prevent="addContent">
              <div class="row m-1">
                <div class="col-12 mb-3">
                  <label class="form-label">Image:</label>
                  <div class="col-12 mb-2">
                    <input
                      class="form-control mt-3"
                      type="file"
                      ref="addImage"
                      accept="image/*"
                      @change="handleUploadImage($event)"
                    />
                  </div>
                  <div class="col-md-12" v-if="previewImage">
                    <img :src="previewImage" width="200" />
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <label class="form-label">Title:</label>
                  <input type="text" v-model="title" class="form-control" placeholder="Enter Title" />
                </div>
                <div class="col-12 mb-3">
                  <label class="form-label">Description:</label>
                  <textarea
                    type="text"
                    v-model="description"
                    class="form-control"
                    placeholder="Enter Description"
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="updateModal"
        tabindex="-1"
        aria-labelledby="updateModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="updateModalLabel">Update Content</h5>
              <button
                type="button"
                id="updateCloseBtn"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form @submit.prevent="updateContent">
              <div class="row m-1">
                <div class="col-12 mb-3">
                  <label class="form-label">Image:</label>
                  <div class="col-12 mb-2">
                    <input
                      class="form-control mt-3"
                      type="file"
                      ref="updateImage"
                      accept="image/*"
                      @change="handleUpdateImage($event)"
                    />
                  </div>
                  <div class="col-md-12">
                    <img v-if="previewUpdateImage" :src="previewUpdateImage" width="200" />
                    <img v-else-if="singleContent.image" :src="singleContent.image" width="200" />
                    <img v-else src="/core/assets/images/default.png" width="200" />
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <label class="form-label">Title:</label>
                  <input
                    type="text"
                    v-model="singleContent.title"
                    class="form-control"
                    placeholder="Enter Title"
                  />
                </div>
                <div class="col-12 mb-3">
                  <label class="form-label">Description:</label>
                  <textarea
                    type="text"
                    v-model="singleContent.description"
                    class="form-control"
                    placeholder="Enter Description"
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/benefits-learn-section.js'></script>

<style scoped>
@import "../../../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.primary-button {
  background-color: #1765db;
}
.delete-button {
  background-color: #f41127;
}
.metaModal {
  color: black;
  cursor: pointer;
  text-decoration: none;
  display: block;
}
.metaModal:hover {
  cursor: pointer;
  color: #09f;
}

.disable-row {
  pointer-events: none;
}
</style>
