import { authHeader } from '../../../auth';
export default {
    name: "ContactReason",
    data() {
        return {
            setURL: this.$serverURL + this.$api.contact.contactReasonURL,
            dataList: [],
            singleData: {},
            contactReasonName: ""
        }
    },
    async created() {
        document.title = "JARS - Contact Reason";
        this.dataList = await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        addContactReason: async function () {
            if (!this.contactReasonName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter contact reason!"
                });
            }
            else {
                let data = {
                    contactReasonName: this.contactReasonName
                }
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: data,
                    headers: authHeader()
                };
                await this.postData(config);
                this.contactReasonName = '';
                this.dataList = await this.getDataList(this.setURL);
                window.$("#addModal").modal('hide');
            }
        },
        editContactReason: async function (data) {
            this.singleData = data;
        },
        updateContactReason: async function () {
            if (!this.singleData.contactReasonName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter contact reason!"
                });
            }
            else {
                let data = {
                    contactReasonName: this.singleData.contactReasonName
                }
                let config = {
                    method: "PATCH",
                    url: `${this.setURL}/${this.singleData.id}` ,
                    data: data,
                    headers: authHeader()
                };
                await this.updateData(config);
                this.contactReasonName = '';
                this.dataList = await this.getDataList(this.setURL);
                window.$("#editModal").modal('hide');
            }
        },
        deleteContactReason: function (id) {
            this.$swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then(async (result) => {
                if (result.isConfirmed) {
                    if (result.isConfirmed) {
                        try {
                            let config = {
                                method: 'DELETE',
                                url: `${this.setURL}/${id}`,
                                headers: authHeader()
                            }
                            await this.deleteData(config);
                            this.dataList = await this.getDataList(this.setURL);

                        } catch (error) {
                            this.$swal.fire({
                                icon: "error",
                                text: "Something went wrong, please try agian later." + error
                            });
                        }
                    }
                }
            })

        }
    }
}