<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Discover</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">Discover CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Discover CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="border border-3 p-4 rounded">
                                    <div class="col-12 mt-5">
                                        <h5>Section One</h5>
                                        <hr>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section One Header:</label>
                                        <input type="text" v-model="cmsData.sectionOneHeader" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section One Title:</label>
                                        <input type="text" v-model="cmsData.sectionOneTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section One Subtitle:</label>
                                        <input type="text" v-model="cmsData.sectionOneSubTitle" class="form-control">
                                    </div>
                                    <div class="col-12 mt-5">
                                        <h5>Section Two</h5>
                                        <hr>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Two Header:</label>
                                        <input type="text" v-model="cmsData.sectionTwoHeader" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Two Title:</label>
                                        <input type="text" v-model="cmsData.sectionTwoTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Two Subtitle:</label>
                                        <input type="text" v-model="cmsData.sectionTwoSubTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Two Description:</label>
                                        <ckeditor :editor="editor" v-model="cmsData.sectionTwoDescription" :config="editorConfig"></ckeditor>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Two Button Text:</label>
                                        <input type="text" v-model="cmsData.sectionTwoButtonText" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Section Two Button Link:</label>
                                        <input type="text" v-model="cmsData.sectionTwoButtonLink" class="form-control">
                                    </div>
                                    <div class="d-grid">
                                        <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-lg-6">
                                <div class="border border-3 p-4 rounded h-100">
                                    <div class="d-flex justify-content-center">
                                        <img style="width: 100%;" src="../../../../../public/core/assets/images/default.png">
                                    </div>
                                    <h6 class="text-center pt-4"><b>Dutchie Custom Key: Cart Page High Deals</b></h6>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/discover-cms.js"></script>