<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Instagram Section</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Instagram Section</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body" style="display: flex; align-items: center;">
        <h5 style="margin: 0;">{{stateName}}</h5>
        <div
          style="display: flex; align-items: center; cursor: pointer; margin-left: auto;"
          @click="$router.push({ path: '/instagram-section-states'})"
        >
          <span style="margin-right: 5px;">
          <i class="bx bxs-arrow-to-left text-dark" style="font-size: 30px;"></i>
          </span>
          <h6 style="margin: 0; cursor:pointer; font-weight: 600;">Go Back</h6>
        </div>
        </div>
      </div>
      <form @submit.prevent="updateCms()">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Instagram Section</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-8 mb-3">
                  <div class="border border-3 p-4 rounded">
                    <div class="mb-3">
                      <label class="form-label">Title:</label>
                      <input
                        type="text"
                        v-model="cmsData.title"
                        class="form-control"
                        placeholder="Enter Title"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">SubTitle:</label>
                      <input
                        type="text"
                        v-model="cmsData.subTitle"
                        class="form-control"
                        placeholder="Enter Title One"
                      />
                    </div>
                    <div class="form-check form-switch">
                      <label class="form-label">
                        [If there is no data for the state, then this data will show as default for the state.]
                      </label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="cmsData.isDefault"
                      />
                    </div>
                  </div>
                </div>
                <div class="d-grid col-8">
                  <button type="submit" class="btn btn-primary">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src="../js/instagram-section-cms.js"></script>