import { createRouter, createWebHistory } from "vue-router";
import guard from "../guard";
import Login from "../components/auth/template/Login";
import Dashboard from "../components/dashboard/template/Dashboard";
import Error404 from "../components/global/error_404/template/Error404";
import Customer from "../components/user/template/Customer";
import Admin from "../components/user/template/Admin";
import Unauthorized from "../components/global/unauthorized/template/Unauthorized";
import ContactPageCms from "../components/cms/contact-page/template/ContactPageCms";
import ContactUsStoreList from "../components/cms/contact-page/template/ContactUsStoreList";
import FaqPageCMS from "../components/cms/faq-page/template/FaqPageCMS";
import QuizPageCMS from "../components/cms/quiz-page/template/QuizPageCMS";
import AboutPageCms from "../components/cms/about-page/template/AboutPageCms";
import CommonBanner from "../components/cms/home/common-banner/template/CommonBanner";
import SectionTwoCMS from "../components/cms/home/section-two-cms/template/SectionTwoCMS";
import SectionThreeCMS from "../components/cms/home/section-three-cms/template/SectionThreeCMS";
import SectionFourCMS from "../components/cms/home/section-four-cms/template/SectionFourCMS";
import SectionFiveCMS from "../components/cms/home/section-five-cms/template/SectionFiveCMS";
import SectionSevenCMS from "../components/cms/home/section-seven-cms/template/SectionSevenCMS";
import SectionEightCMS from "../components/cms/home/section-eight-cms/template/SectionEightCMS";
import SectionNineCMS from "../components/cms/home/section-nine-cms/template/SectionNineCMS";
import HighDealsCMS from "../components/cms/high-deals/template/HighDealsCMS";
import NewDropBannerCMS from "../components/cms/home/new-drop-banner/template/NewDropBannerCMS";
import CareerPageCMS from "../components/cms/career-page/template/CareerPageCMS";
import HeaderCMS from "../components/cms/header-cms/template/HeaderCMS";
import AgeVerificationPageCMS from "../components/cms/age-verification/template/AgeVerificationPageCMS";
import LandingPageCms from "../components/cms/landing-page/template/LandingPageCms";
import CartPageCms from "../components/cms/cart-page/template/CartPageCMS";
import ArticlePageCms from "../components/cms/article-page/template/ArticlePageCMS";
import ArticleDetailsPageCMS from '../components/cms/article-details-page/template/ArticleDetailsPageCMS';
import PressPageCms from "../components/cms/press-page/template/PressPageCMS";
import FooterCMS from "../components/cms/footer-cms/template/FooterCMS";
import PrivacyPolicyCMS from "../components/cms/privacy-policy/template/PrivacyPolicyCms";
import TermsOfServiceCMS from "../components/cms/terms-of-service/template/TermsOfServiceCms";
import CookiesSettingsCMS from "../components/cms/cookies-settings/template/CookiesSettingsCms";
import ServiceTypeCMS from "../components/cms/service-type/template/ServiceTypeCMS";
import HowToEnjoyCMS from "../components/cms/how-to-enjoy/template/HowToEnjoyCMS";
import RelatedProductsCMS from "../components/cms/related-products/template/RelatedProductsCMS";
import DiscoverCMS from "../components/cms/discover-cms/template/DiscoverCMS";
import ReserveTimeCMS from "../components/cms/reserve-time/template/ReserveTimeCMS";
import SocialLinks from "../components/cms/social-links/template/SocialLinks";
import SiteMapCMS from "../components/cms/site-map/template/SiteMapCMS";
import Faq from "../components/cms/faq/template/Faq";
import HomeSlider from "../components/cms/home/home-slider/template/HomeSlider";
import AddSlider from "../components/cms/home/home-slider/template/AddSlider";
import EditSlider from "../components/cms/home/home-slider/template/EditSlider";
import SectionFourSlider from "../components/cms/home/section-four-slider/template/SectionFourSlider";
import PressRelease from "../components/press-release/template/PressRelease"
import AddPressRelease from "../components/press-release/template/AddPressRelease";
import EditPressRelease from "../components/press-release/template/EditPressRelease";
import AboutSection from "../components/cms/about-page-section-two/template/AboutSection";
import AddSection from "../components/cms/about-page-section-two/template/AddSection";
import EditSection from "../components/cms/about-page-section-two/template/EditSection";
import Career from "../components/cms/career/template/Career";
import AddCareer from "../components/cms/career/template/AddCareer";
import EditCareer from "../components/cms/career/template/EditCareer";
import CareerApplicant from "../components/career-applicant/template/CareerApplicant";
import CareerDepartment from "../components/career-department/template/CareerDepartment";
import AddCareerDepartment from "../components/career-department/template/AddCareerDepartment";
import EditCareerDepartment from "../components/career-department/template/EditCareerDepartment";
import Feedback from "../components/feedback/template/Feedback";
import ProductReview from "../components/product-review/template/ProductReview";
import AboutComponentUI from "../components/component-ui-cms/about-us/template/AboutComponentUI";
import HomeComponentUI from "../components/component-ui-cms/home-page/template/HomeComponentUI";
import discoverHighlightsComponentUI from "../components/component-ui-cms/product-details/discover-highlights/template/discoverHighlightsComponentUI";
import TermsComponentUI from "../components/component-ui-cms/terms-condition/template/TermsComponentUI";
import PrivacyComponentUI from "../components/component-ui-cms/privacy-policy/template/PrivacyComponentUI";
import CookiesComponentUI from "../components/component-ui-cms/cookies-settings/template/CookiesComponentUI";
import ContactComponentUI from "../components/component-ui-cms/contact/template/ContactComponentUI";
import CareerComponentUI from "../components/component-ui-cms/career/template/CareerComponentUI";
import CareerDetailsComponentUI from "../components/component-ui-cms/career-details/template/CareerDetailsComponentUI";
import FaqComponentUI from "../components/component-ui-cms/faq/template/FaqComponentUI";
import LandingComponentUI from "../components/component-ui-cms/landing-page/template/LandingComponentUI";
import ArticleComponentUI from "../components/component-ui-cms/article/template/ArticleComponentUI";
import ArticleDetailsComponentUI from "../components/component-ui-cms/article-details/template/ArticleDetailsComponentUI";
import PressComponentUI from "../components/component-ui-cms/press/template/PressComponentUI";
import PressDetailsComponentUI from "../components/component-ui-cms/press-details/template/PressDetailsComponentUI";
import Store from "../components/store/template/Store";
import BlogCategory from "../components/blog/template/BlogCategory";
import BlogTag from "../components/blog/template/BlogTag";
import BlogPost from "../components/blog/template/BlogPost";
import AddBlogPost from "../components/blog/template/AddBlogPost";
import EditBlogPost from "../components/blog/template/EditBlogPost";
import Contact from "../components/contact/template/Contact";
import Category from "../components/category/template/Category";
import Quiz from "../components/cms/quiz/template/Quiz";
import Synchronize from "../components/synchronize/template/Synchronize";
import FeaturedBrand from "../components/featured-brand/template/FeaturedBrand";
import SectionOneCMS from "../components/cms/jars-plus/section-one/template/SectionOneCMS";
import SectionTwoTop from "../components/cms/jars-plus/section-two-top/template/SectionTwoTop";
import SectionTwoBottom from "../components/cms/jars-plus/section-two-bottom/template/SectionTwoBottom";
import SectionThree from "../components/cms/jars-plus/section-three/template/SectionThree";
import ShopPage from "../components/shop-page/template/ShopPage";
import AIQPersonas from "../components/aiq-personas/template/AIQPersonas";
import SitePageTitleCMS from "../components/cms/site-page-title-cms/template/SitePageTitleCMS";
import SitePageMetaCMS from "../components/cms/site-page-meta-cms/template/SitePageMetaCMS";
import Retailer from "../components/retailer/template/Retailer";
import HeaderBannerCMS from "../components/cms/header-banner-cms/template/HeaderBannerCMS";
import AiqService from "../components/AIQ-service/template/AiqService";
import InstagramSectionCMS from "../components/cms/instagram-section-cms/template/instagramSectionCMS";
import ProductMetaMapping from "../components/cms/product-meta-mapping/template/ProductMetaMapping";
import PageSchema from "../components/cms/page-schema/template/PageSchema";
import RobotTxt from "../components/cms/robot-txt/template/RobotTxt";
import StepsSectionCMS from "../components/cms/jars-plus/steps-section/template/StepsSection";
import BenefitsSectionCMS from "../components/cms/jars-plus/benefits-section/template/BenefitsSection";
import BenefitsModal from "../components/jarsBenefitsSectionModule/template/BenefitsModal";
import AddBenefitsModal from "../components/jarsBenefitsSectionModule/template/AddBenefits";
import EditBenefitsModal from "../components/jarsBenefitsSectionModule/template/EditBenefits";
import AboutPageHeaderSectionCMS from "../components/cms/new-about-page/about-page-header-section/template/AboutPageHeaderSection";
import AboutPageSectionOneCMS from "../components/cms/new-about-page/about-page-section-one/template/AboutPageSectionOne";
import AboutPageSectionTwoCMS from "../components/cms/new-about-page/about-page-section-two/template/AboutPageSectionTwo";
import AboutPageSectionThreeCMS from "../components/cms/new-about-page/about-page-section-three/template/AboutPageSectionThree";
import TopTickerComponentUI from "../components/component-ui-cms/top-ticker/template/TopTickerComponentUI";
import BenefitsLearnSection from "../components/cms/jars-plus/benefits-learn-section/template/BenefitsLearnSection";
import SectionTwoStateList from "../components/cms/home/section-two-cms/template/SectionTwoStateList";
import NewDropStateList from "../components/cms/home/new-drop-banner/template/NewDropStateList";
import SectionFiveStateList from "../components/cms/home/section-five-cms/template/SectionFiveStateList";
import SectionSevenStateList from "../components/cms/home/section-seven-cms/template/SectionSevenStateList";
import SectionEightStateList from "../components/cms/home/section-eight-cms/template/SectionEightStateList";
import SectionNineStateList from "../components/cms/home/section-nine-cms/template/SectionNineStateList";
import InstagramStateList from "../components/cms/instagram-section-cms/template/InstagramStateList";
import SectionThreeStateList from "../components/cms/home/section-three-cms/template/SectionThreeStateList";
import SectionFourStateList from "../components/cms/home/section-four-cms/template/SectionFourStateList";
import SectionFourSliderStateList from "../components/cms/home/section-four-slider/template/SectionFourSliderStateList";
import CommonBannerStateList from "../components/cms/home/common-banner/template/CommonBannerStateList";
import HomeSliderStateList from "../components/cms/home/home-slider/template/HomeSliderStateList";
import UserPageBannerCms from "../components/cms/user-page/user-page-banner-cms/template/UserPageBannerCms";
import UserPageComponentUI from "../components/component-ui-cms/user/user-page-banner/template/UserPageBannerUI";
import HomePageSubNav from "../components/home-page-sub-nav/template/HomePageSubNav";
import AddHomePageSubNav from "../components/home-page-sub-nav/template/AddHomePageSubNav";
import EditHomePageSubNav from "../components/home-page-sub-nav/template/EditHomePageSubNav";
import ArticleCategory from "../components/article-category/template/ArticleCategory";
import AddArticleCategory from "../components/article-category/template/AddArticleCategory";
import EditArticleCategory from "../components/article-category/template/EditArticleCategory";
import PressReleaseCategory from "../components/press-release-category/template/PressReleaseCategory";
import AddPressReleaseCategory from "../components/press-release-category/template/AddPressReleaseCategory";
import EditPressReleaseCategory from "../components/press-release-category/template/EditPressReleaseCategory";
import ContactReason from "../components/contact-reason/template/ContactReason";



const routes = [
	/* Auth Routes */
	{ path: "/admin-login", name: "Login", component: Login },
	{ path: "/", redirect: { name: 'Home' } },
	{ path: "/home", name: "Home", component: Dashboard, beforeEnter: guard },
	{ path: "/admins", name: "Admin", component: Admin, beforeEnter: guard },
	{ path: "/customers", name: "Customer", component: Customer, beforeEnter: guard },

	/* Error and Unauthorized routes */
	{ path: "/404-not-found", name: "404 Not Found", component: Error404, beforeEnter: guard },
	{ path: "/unauthorized", name: "Unauthorized", component: Unauthorized, beforeEnter: guard },
	{ path: '/:pathMatch(.*)*', component: Error404, beforeEnter: guard },

	/* Retailer AIQ Service Routes */
	{ path: '/retailer-aiq', name: "AiqService", component: AiqService, beforeEnter: guard },

	/* Category Route */
	{ path: '/Category', component: Category, beforeEnter: guard },

	//cms states
	{ path: "/home-slider", name: "HomeSlider", component: HomeSlider, beforeEnter: guard },
	{ path: "/home-slider-states", name: "HomeSliderStateList", component: HomeSliderStateList, beforeEnter: guard },


	{ path: "/section-two-cms", name: "SectionTwoCMS", component: SectionTwoCMS, beforeEnter: guard },
	{ path: "/section-two-states", name: "SectionTwoStateList", component: SectionTwoStateList, beforeEnter: guard },

	{ path: "/section-three-cms", name: "SectionThreeCMS", component: SectionThreeCMS, beforeEnter: guard },
	{ path: "/section-three-states", name: "SectionThreeStateList", component: SectionThreeStateList, beforeEnter: guard },

	{ path: "/section-four-cms", name: "SectionFourCMS", component: SectionFourCMS, beforeEnter: guard },
	{ path: "/section-four-states", name: "SectionFourStateList", component: SectionFourStateList, beforeEnter: guard },

	{ path: "/section-four-slider", name: "SectionFourSlider", component: SectionFourSlider, beforeEnter: guard },
	{ path: "/section-four-slider-states", name: "SectionFourSliderStateList", component: SectionFourSliderStateList, beforeEnter: guard },

	{ path: "/common-banner-cms", name: "CommonBanner", component: CommonBanner, beforeEnter: guard },
	{ path: "/common-banner-states", name: "CommonBannerStateList", component: CommonBannerStateList, beforeEnter: guard },


	{ path: "/new-drop-banner-cms", name: "NewDropBannerCMS", component: NewDropBannerCMS, beforeEnter: guard },
	{ path: "/new-drop-banner-states", name: "NewDropStateList", component: NewDropStateList, beforeEnter: guard },

	{ path: "/section-five-cms", name: "SectionFiveCMS", component: SectionFiveCMS, beforeEnter: guard },
	{ path: "/section-five-states", name: "SectionFiveStateList", component: SectionFiveStateList, beforeEnter: guard },

	{ path: "/section-seven-cms", name: "SectionSevenCMS", component: SectionSevenCMS, beforeEnter: guard },
	{ path: "/section-seven-states", name: "SectionSevenStateList", component: SectionSevenStateList, beforeEnter: guard },

	{ path: "/section-eight-cms", name: "SectionEightCMS", component: SectionEightCMS, beforeEnter: guard },
	{ path: "/section-eight-states", name: "SectionEightStateList", component: SectionEightStateList, beforeEnter: guard },

	{ path: "/section-nine-cms", name: "SectionNineCMS", component: SectionNineCMS, beforeEnter: guard },
	{ path: "/section-nine-states", name: "SectionNineStateList", component: SectionNineStateList, beforeEnter: guard },

	{ path: "/instagram-section-cms", name: "InstagramSectionCMS", component: InstagramSectionCMS, beforeEnter: guard },
	{ path: "/instagram-section-states", name: "InstagramStateList", component: InstagramStateList, beforeEnter: guard },

	/* CMS Routes */
	{ path: "/contact-page-store-list", name: "ContactUsStoreList", component: ContactUsStoreList, beforeEnter: guard },
	{ path: "/contact-page-cms", name: "ContactPageCms", component: ContactPageCms, beforeEnter: guard },
	{ path: "/faq-page-cms", name: "FaqPageCMS", component: FaqPageCMS, beforeEnter: guard },
	{ path: "/quiz-page-cms", name: "QuizPageCMS", component: QuizPageCMS, beforeEnter: guard },
	{ path: "/about-page-cms", name: "AboutPageCms", component: AboutPageCms, beforeEnter: guard },
	{ path: "/contact-reason", name: "ContactReason", component: ContactReason, beforeEnter: guard },
	
	{ path: "/add-slider", name: "AddSlider", component: AddSlider, beforeEnter: guard },
	{ path: "/edit-slider/:id", name: "EditSlider", component: EditSlider, beforeEnter: guard },
	

	{ path: "/high-deals-cms", name: "HighDealsCMS", component: HighDealsCMS, beforeEnter: guard },

	{ path: "/career-page-cms", name: "CareerPageCMS", component: CareerPageCMS, beforeEnter: guard },
	{ path: "/header-cms", name: "HeaderCMS", component: HeaderCMS, beforeEnter: guard },
	{ path: "/age-verification-page-cms", name: "AgeVerificationPageCMS", component: AgeVerificationPageCMS, beforeEnter: guard },
	{ path: "/landing-page-cms", name: "LandingPageCms", component: LandingPageCms, beforeEnter: guard },
	{ path: "/cart-page-cms", name: "CartPageCms", component: CartPageCms, beforeEnter: guard },
	{ path: "/article-page-cms", name: "ArticlePageCms", component: ArticlePageCms, beforeEnter: guard },
	{ path: "/article-details-page-cms", name: "ArticleDetailsPageCMS", component: ArticleDetailsPageCMS, beforeEnter: guard },
	{ path: "/press-page-cms", name: "PressPageCms", component: PressPageCms, beforeEnter: guard },
	{ path: "/footer-cms", name: "FooterCMS", component: FooterCMS, beforeEnter: guard },
	{ path: "/service-type-cms", name: "ServiceTypeCMS", component: ServiceTypeCMS, beforeEnter: guard },
	{ path: '/privacy-policy-cms', name: 'PrivacyPolicyCMS', component: PrivacyPolicyCMS, beforeEnter: guard },
	{ path: '/terms-of-service-cms', name: 'TermsOfServiceCMS', component: TermsOfServiceCMS, beforeEnter: guard },
	{ path: '/cookies-settings-cms', name: 'CookiesSettingsCMS', component: CookiesSettingsCMS, beforeEnter: guard },
	{ path: '/social-link', name: 'SocialLinks', component: SocialLinks, beforeEnter: guard },
	{ path: '/site-map', name: 'SiteMapCMS', component: SiteMapCMS, beforeEnter: guard },
	{ path: '/faq', name: 'Faq', component: Faq, beforeEnter: guard },
	{ path: '/quiz', name: 'Quiz', component: Quiz, beforeEnter: guard },
	{ path: "/about-section", name: "AboutSection", component: AboutSection, beforeEnter: guard },
	{ path: "/add-section", name: "AddSection", component: AddSection, beforeEnter: guard },
	{ path: "/edit-section/:id", name: "EditSection", component: EditSection, beforeEnter: guard },
	{ path: "/career", name: "Career", component: Career, beforeEnter: guard },
	{ path: "/add-career", name: "AddCareer", component: AddCareer, beforeEnter: guard },
	{ path: "/edit-career/:id", name: "EditCareer", component: EditCareer, beforeEnter: guard },
	{ path: "/press-release", name: "PressRelease", component: PressRelease, beforeEnter: guard },
	{ path: "/add-press-release", name: "AddPressRelease", component: AddPressRelease, beforeEnter: guard },
	{ path: "/edit-press-release/:id", name: "EditPressRelease", component: EditPressRelease, beforeEnter: guard },
	{ path: '/how-to-enjoy-cms', name: 'HowToEnjoyCMS', component: HowToEnjoyCMS, beforeEnter: guard },
	{ path: '/related-products-cms', name: 'RelatedProductsCMS', component: RelatedProductsCMS, beforeEnter: guard },
	{ path: '/discover-cms', name: 'DiscoverCMS', component: DiscoverCMS, beforeEnter: guard },
	{ path: '/reserve-time-cms', name: 'ReserveTimeCMS', component: ReserveTimeCMS, beforeEnter: guard },
	{ path: '/section-one-cms', name: 'SectionOneCMS', component: SectionOneCMS, beforeEnter: guard },
	{ path: '/section-two-top', name: 'SectionTwoTop', component: SectionTwoTop, beforeEnter: guard },
	{ path: '/section-two-bottom', name: 'SectionTwoBottom', component: SectionTwoBottom, beforeEnter: guard },
	{ path: '/section-three', name: 'SectionThree', component: SectionThree, beforeEnter: guard },
	{ path: '/site-page-title-cms', name: 'SitePageTitleCMS', component: SitePageTitleCMS, beforeEnter: guard },
	{ path: '/site-page-meta-cms', name: 'SitePageMetaCMS', component: SitePageMetaCMS, beforeEnter: guard },
	{ path: "/header-banner-cms", name: "HeaderBannerCMS", component: HeaderBannerCMS, beforeEnter: guard },
	{ path: "/product-meta-mapping", name: "ProductMetaMapping", component: ProductMetaMapping, beforeEnter: guard },
	{ path: "/page-schema", name: "PageSchema", component: PageSchema, beforeEnter: guard },
	{ path: "/robot-txt-cms", name: "RobotTxt", component: RobotTxt, beforeEnter: guard },
	{ path: "/steps-section-cms", name: "StepsSectionCMS", component: StepsSectionCMS, beforeEnter: guard },
	{ path: "/benefits-section-cms", name: "BenefitsSectionCMS", component: BenefitsSectionCMS, beforeEnter: guard },
	{ path: "/user-page-banner-cms", name: "UserPageBannerCms", component: UserPageBannerCms, beforeEnter: guard },

	{ path: "/benefits-learn-more-section", name: "BenefitsLearnSection", component: BenefitsLearnSection, beforeEnter: guard },

	{ path: "/about-page-header-section-cms", name: "AboutPageHeaderSectionCMS", component: AboutPageHeaderSectionCMS, beforeEnter: guard },
	{ path: "/about-page-section-one-cms", name: "AboutPageSectionOneCMS", component: AboutPageSectionOneCMS, beforeEnter: guard },
	{ path: "/about-page-section-two-cms", name: "AboutPageSectionTwoCMS", component: AboutPageSectionTwoCMS, beforeEnter: guard },
	{ path: "/about-page-section-three-cms", name: "AboutPageSectionThreeCMS", component: AboutPageSectionThreeCMS, beforeEnter: guard },
	{ path: "/top-ticker-component-ui", name: "TopTickerComponentUI", component: TopTickerComponentUI, beforeEnter: guard },
	{ path: "/user-page-banner-component-ui", name: "UserPageComponentUI", component: UserPageComponentUI, beforeEnter: guard },

	//state list
	// { path: "/state-list", name: "StateList", component: StateList, beforeEnter: guard },

	/* Career Applicant Routes */
	{ path: "/career-applicant", name: "CareerApplicant", component: CareerApplicant, beforeEnter: guard },
	{ path: "/career-department", name: "CareerDepartment", component: CareerDepartment, beforeEnter: guard },
	{ path: "/add-career-department", name: "AddCareerDepartment", component: AddCareerDepartment, beforeEnter: guard },
	{ path: "/edit-career-department/:id", name: "EditCareerDepartment", component: EditCareerDepartment, beforeEnter: guard },

	/* Feedback Routes */
	{ path: "/feedback", name: "Feedback", component: Feedback, beforeEnter: guard },

	/* Synchronize Routes */
	{ path: "/synchronize", name: "Synchronize", component: Synchronize, beforeEnter: guard },

	/* Shop Page Routes */
	{ path: "/shop-page", name: "ShopPage", component: ShopPage, beforeEnter: guard },

	/* AIQ Personas Routes */
	{ path: "/aiq-personas/:id", name: "AIQPersonas", component: AIQPersonas, beforeEnter: guard },

	/* Featured Brands Routes */
	{ path: "/featured-brand", name: "FeaturedBrand", component: FeaturedBrand, beforeEnter: guard },

	/* Product Review Routes */
	{ path: "/product-review", name: "ProductReview", component: ProductReview, beforeEnter: guard },

	/* Store Routes */
	{ path: "/store", name: "Store", component: Store, beforeEnter: guard },

	/* Contact Routes */
	{ path: "/contact", name: "Contact", component: Contact, beforeEnter: guard },

	/* Blog Routes */
	{ path: "/blog-category", name: "BlogCategory", component: BlogCategory, beforeEnter: guard },
	{ path: "/blog-tag", name: "BlogTag", component: BlogTag, beforeEnter: guard },
	{ path: "/blog-post", name: "BlogPost", component: BlogPost, beforeEnter: guard },
	{ path: "/add-blog-post", name: "AddBlogPost", component: AddBlogPost, beforeEnter: guard },
	{ path: "/edit-blog-post/:id", name: "EditBlogPost", component: EditBlogPost, beforeEnter: guard },

	// Home Page sub nav
	{ path: "/home-page-sub-nav", name: "HomePageSubNav", component: HomePageSubNav, beforeEnter: guard },
	{ path: "/add-home-page-sub-nav", name: "AddHomePageSubNav", component: AddHomePageSubNav, beforeEnter: guard },
	{ path: "/edit-home-page-sub-nav/:id", name: "EditHomePageSubNav", component: EditHomePageSubNav, beforeEnter: guard },

	// Article Category
	{ path: "/article-category", name: "ArticleCategory", component: ArticleCategory, beforeEnter: guard },
	{ path: "/add-article-category", name: "AddArticleCategory", component: AddArticleCategory, beforeEnter: guard },
	{ path: "/edit-article-category/:id", name: "EditArticleCategory", component: EditArticleCategory, beforeEnter: guard },

	// Press Release Category
	{ path: "/press-release-category", name: "PressReleaseCategory", component: PressReleaseCategory, beforeEnter: guard },
	{ path: "/add-press-release-category", name: "AddPressReleaseCategory", component: AddPressReleaseCategory, beforeEnter: guard },
	{ path: "/edit-press-release-category/:id", name: "EditPressReleaseCategory", component: EditPressReleaseCategory, beforeEnter: guard },

	/* Jars + page Benefits Modal */
	{ path: "/benefits-modal", name: "BenefitsModal", component: BenefitsModal, beforeEnter: guard },
	{ path: "/add-benefits-modal", name: "AddBenefitsModal", component: AddBenefitsModal, beforeEnter: guard },
	{ path: "/edit-benefits-modal/:id", name: "EditBenefitsModal", component: EditBenefitsModal, beforeEnter: guard },

	/* Retailer Routes */
	{ path: '/retailer', name: "Retailer", component: Retailer, beforeEnter: guard },

	/* Component UI Routes */
	{ path: "/discover-highlights-component-ui", name: "discoverHighlightsComponentUI", component: discoverHighlightsComponentUI, beforeEnter: guard },
	{ path: "/about-page-component-ui", name: "AboutComponentUI", component: AboutComponentUI, beforeEnter: guard },
	{ path: "/terms-condition-page-component-ui", name: "TermsComponentUI", component: TermsComponentUI, beforeEnter: guard },
	{ path: "/privacy-policy-page-component-ui", name: "PrivacyComponentUI", component: PrivacyComponentUI, beforeEnter: guard },
	{ path: "/cookies-settings-page-component-ui", name: "CookiesComponentUI", component: CookiesComponentUI, beforeEnter: guard },
	{ path: "/contact-page-component-ui", name: "ContactComponentUI", component: ContactComponentUI, beforeEnter: guard },
	{ path: "/career-page-component-ui", name: "CareerComponentUI", component: CareerComponentUI, beforeEnter: guard },
	{ path: "/career-details-page-component-ui", name: "CareerDetailsComponentUI", component: CareerDetailsComponentUI, beforeEnter: guard },
	{ path: "/faq-page-component-ui", name: "FaqComponentUI", component: FaqComponentUI, beforeEnter: guard },
	{ path: "/landing-page-component-ui", name: "LandingComponentUI", component: LandingComponentUI, beforeEnter: guard },
	{ path: "/article-page-component-ui", name: "ArticleComponentUI", component: ArticleComponentUI, beforeEnter: guard },
	{ path: "/article-details-page-component-ui", name: "ArticleDetailsComponentUI", component: ArticleDetailsComponentUI, beforeEnter: guard },
	{ path: "/press-page-component-ui", name: "PressComponentUI", component: PressComponentUI, beforeEnter: guard },
	{ path: "/press-details-page-component-ui", name: "PressDetailsComponentUI", component: PressDetailsComponentUI, beforeEnter: guard },
	{ path: "/home-page-component-ui", name: "HomeComponentUI", component: HomeComponentUI, beforeEnter: guard }
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

export default router;
