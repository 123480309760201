export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.headerCmsURL,
            cmsData: {},
            logo: "",
            previewLogo: ""
        }
    },
    async created() {
        document.title = "JARS - Header CMS";
        this.getData();
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        uploadLogo: function (event) {
            this.logo = event.target.files[0];
            let input = this.$refs.logo;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewLogo = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        async update() {
            try {
                let formData = new FormData();
                formData.append('bannerText', this.cmsData.bannerText ? this.cmsData.bannerText : '');
                formData.append('logo', this.logo ? this.logo : this.cmsData.logo ? this.cmsData.logo : '');
                formData.append('headerText', this.cmsData.headerText ? this.cmsData.headerText : '');
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, formData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    this.$refs.logo.value = "";
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}