<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">About Page Section One CMS</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">About Page Section One CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <form @submit.prevent="updateCms()">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">About Page Section One CMS</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-6 mb-3">
                    <div class="border border-3 p-4 rounded h-100">
                        <div class="mb-3">
                            <label class="form-label">Title:</label>
                            <input type="text" v-model="cmsData.title" class="form-control">
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Sub Title:</label>
                             <textarea
                            class="form-control"
                            rows="3"
                            v-model="cmsData.subTitle"
                            placeholder="Enter description one"
                            ></textarea>
                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label">Description One:</label>
                            <textarea
                            class="form-control"
                            rows="3"
                            v-model="cmsData.descriptionOne"
                            placeholder="Enter description one"
                            ></textarea>
                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label">Description Two:</label>
                            <textarea
                            class="form-control"
                            rows="3"
                            v-model="cmsData.descriptionTwo"
                            placeholder="Enter description two"
                            ></textarea>
                        </div>
                        <div class="d-grid">
                            <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src="../js/about-page-section-one.js"></script>