export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.benefitsSectionCmsURL,
            cmsData: {
                title: "",
                subTitle: "",
                sectionButtonText: "",
            },

            contentList: [],

            image: "",
            title: "",
            description: "",
            buttonText: "",

            singleContent: {
                image: "",
                title: "",
                description: "",
                buttonText: "",
            },

            previewImage: "",
            previewUpdateImage: "",

        }
    },
    async created() {
        document.title = "Jars - Steps Section CMS";
        this.getData();
    },
    methods: {
        async getData() {
            this.contentList = [];
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.data.length > 0) ? response.data.data[0] : {};
                this.contentList = response.data.benefitsContent;
            }
            else {
                this.cmsData = {};
            }
        },
        handleUploadImage(event) {
            this.image = event.target.files[0];
            let input = this.$refs.addImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        editContent(data) {
            this.singleContent = data;
        },
        handleUpdateImage(event) {
            this.singleContent.image = event.target.files[0];
            let input = this.$refs.updateImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewUpdateImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async updateCms() {
            try {
                let fromData = new FormData();
                fromData.append('title', this.cmsData.title);
                fromData.append('subTitle', this.cmsData.subTitle);
                fromData.append('imageOne', this.cmsData.imageOne);
                fromData.append('titleOne', this.cmsData.titleOne);
                fromData.append('descriptionOne', this.cmsData.descriptionOne);
                fromData.append('buttonOneText', this.cmsData.buttonOneText);
                fromData.append('buttonOneLink', this.cmsData.buttonOneLink);
                fromData.append('imageTwo', this.cmsData.imageTwo);
                fromData.append('titleTwo', this.cmsData.titleTwo);
                fromData.append('descriptionTwo', this.cmsData.descriptionTwo);
                fromData.append('buttonTwoText', this.cmsData.buttonTwoText);
                fromData.append('buttonTwoLink', this.cmsData.buttonTwoLink);
                fromData.append('imageThree', this.cmsData.imageThree);
                fromData.append('titleThree', this.cmsData.titleThree);
                fromData.append('descriptionThree', this.cmsData.descriptionThree);
                fromData.append('buttonThreeText', this.cmsData.buttonThreeText);
                fromData.append('buttonThreeLink', this.cmsData.buttonThreeLink);
                fromData.append('sectionButtonText', this.cmsData.sectionButtonText);
                fromData.append('sectionButtonLink', this.cmsData.sectionButtonLink);
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, fromData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        },

        async addContent() {
            try {
                let token = localStorage.getItem('token');
                let formData = new FormData();
                formData.append('image', this.image);
                formData.append('title', this.title);
                formData.append('description', this.description);
                formData.append('buttonText', this.buttonText);
                this.$swal.showLoading();
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.cms.aboutBenifitContent,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                };
                let response = await this.postData(config);
                if (response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });

                }
                this.title = "";
                this.image = "";
                this.description = "";
                this.buttonText = "";
                this.previewImage = "";
                this.$refs.addImage.value = null;
                await this.getData();
                document.getElementById('addBtn').click();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        },

        async updateContent() {
            try {
                let token = localStorage.getItem('token');
                let formData = new FormData();
                formData.append('image', this.singleContent.image);
                formData.append('title', this.singleContent.title);
                formData.append('description', this.singleContent.description);
                formData.append('buttonText', this.singleContent.buttonText);
                this.$swal.showLoading();
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.cms.aboutBenifitContent + '/' + this.singleContent.id,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                };
                let response = await this.updateData(config);
                if (response.statusCode == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.singleContent = {
                    image: "",
                    title: "",
                    description: "",
                    buttonText: "",
                }
                this.previewUpdateImage = "";
                this.$refs.updateImage.value = null;
                await this.getData();
                document.getElementById('updateCloseBtn').click();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        },

        deleteContent: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let token = localStorage.getItem('token');
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.cms.aboutBenifitContent + '/' + id,
                            headers: {
                                "Authorization": "Bearer " + token
                            }
                        }
                        await this.deleteData(config);
                        await this.getData();
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                }
            })

        }
    }
}