export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.cartPageCmsURL,
            cmsData: {},
            preview: {
                topBannerImage: "",
                bottomBannerImage: ""
            }
        }
    },
    async created() {
        document.title = "Jars - Cart Page CMS";
        this.getData();
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        handleUploadImage(imageKey, previewImageKey, ref, event) {
            this.cmsData[imageKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.preview[previewImageKey] = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        async updateCms() {
            try {
                let fromData = new FormData();
                fromData.append('topBannerImage', this.cmsData.topBannerImage);
                fromData.append('topBannerTitle', this.cmsData.topBannerTitle);
                fromData.append('topBannerSubtitle', this.cmsData.topBannerSubtitle);
                fromData.append('topBannerButtonText', this.cmsData.topBannerButtonText);
                fromData.append('topBannerButtonLink', this.cmsData.topBannerButtonLink);
                fromData.append('bottomBannerImage', this.cmsData.bottomBannerImage);
                fromData.append('bottomTitleOne', this.cmsData.bottomTitleOne);
                fromData.append('bottomSubtitleOne', this.cmsData.bottomSubtitleOne);
                fromData.append('bottomButtonOneText', this.cmsData.bottomButtonOneText);
                fromData.append('bottomButtonOneLink', this.cmsData.bottomButtonOneLink);
                fromData.append('bottomTitleTwo', this.cmsData.bottomTitleTwo);
                fromData.append('bottomSubtitleTwo', this.cmsData.bottomSubtitleTwo);
                fromData.append('bottomButtonTwoText', this.cmsData.bottomButtonTwoText);
                fromData.append('bottomButtonTwoLink', this.cmsData.bottomButtonTwoLink);
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, fromData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$refs.topBannerImage.value = null;
                    this.$refs.bottomBannerImage.value = null;
                    this.preview = {
                        topBannerImage: "",
                        bottomBannerImage: ""
                    }
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}