import {authHeader} from "../../../auth";

export default {
    name: "BlogTag",
    data() {
        return {
            tagList: [],
            tagName: null,
            singleTag: []
        }
    },
    async created() {
        document.title = "JARS - Article Tag";
        await this.getTagList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getTagList: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.tagURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.tagList = response.data.data;
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        addTag: function() {
            if(!this.tagName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please insert tag name!"
                });
                return;
            }
            let data = {
                tagName: this.tagName
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.blog.tagURL,
                data: data,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    this.tagName = "";
                }
                this.getTagList();
                document.getElementById("closeButton").click();
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        deleteTag: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
                }).then(async(result) => {
                    if (result.isConfirmed) {
                        try {
                            let config = {
                                method: 'DELETE',
                                url: this.$serverURL + this.$api.blog.tagURL +'/'+ id,
                                headers: authHeader()
                            }
                            await this.deleteData(config);
                            this.dataList = await this.getTagList(this.setURL);
                        } catch (error) {
                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        }
                    }
            })
            
        },
        setId: function(index) {
            this.singleTag = this.tagList[index];
        },
        updateTag: function(id) {
            let data = {
                tagName: this.singleTag.tagName
            };
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.blog.tagURL +'/'+ id,
                data: data,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.getTagList();
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }
}
