import { authHeader } from "../../../../auth";
export default {
    data() {
        return {
            // productList: [],
            // productUrl: this.$serverURL + this.$api.product.productAPI,
            staticSiteMapUrl: this.$serverURL + this.$api.cms.siteMap.StaticSiteMapCms,
            generateSitemapUrl: this.$serverURL + this.$api.cms.siteMap.SitemapGenerate,
            categoryURL: this.$serverURL + this.$api.category.requestURL,
            categoryList: [],
            blogUrl: this.$serverURL + this.$api.blog.postURL,
            blogList: [],
            pressURL: this.$serverURL + this.$api.pressRelease.pressReleaseURL,
            pressList: [],
            dynamicSitemap: '',
            staticSitemap: '',
            siteBaseUrl: 'https://jars.1space.co'
        }
    },
    async mounted() {
        document.title = "Jars - Site Map CMS";
    },
    async created() {
        this.categoryList = await this.getDataList(this.categoryURL);
        await this.getBlogList();
        this.pressList = await this.getDataList(this.pressURL);
        const siteMapData = await this.getCmsData(this.staticSiteMapUrl);
        if(siteMapData?.data?.length) {
            this.staticSitemap = siteMapData?.data?.[0]?.siteMap;
        }

        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const dateString = `${year}-${month}-${day}`;

        const categorySlugList = this.categoryList.map(x => {
            return x.urlSlug ? x.urlSlug : this.createUrlSlug(x.categoryName);
        });

        const categorySiteMap = categorySlugList.map(slug => {
            return `<url>\n\t<loc>${this.siteBaseUrl}/category/${slug}</loc>\n\t<lastmod>${dateString}</lastmod>\n</url>`;
        });

        const blogSlugList = this.blogList.map(x => {
            return x.urlSlug ? x.urlSlug : this.createUrlSlug(x.categoryName);
        });

        const blogSiteMap = blogSlugList.map(slug => {
            return `<url>\n\t<loc>${this.siteBaseUrl}/blog/${slug}</loc>\n\t<lastmod>${dateString}</lastmod>\n</url>`;
        });

        const pressSlugList = this.pressList.map(x => {
            return x.urlSlug ? x.urlSlug : this.createUrlSlug(x.categoryName);
        });

        const pressMap = pressSlugList.map(slug => {
            return `<url>\n\t<loc>${this.siteBaseUrl}/press/${slug}</loc>\n\t<lastmod>${dateString}</lastmod>\n</url>`;
        });

        this.dynamicSitemap = categorySiteMap.join('\n');
        this.dynamicSitemap += blogSiteMap.join('\n');
        this.dynamicSitemap += pressMap.join('\n');
    },
    methods: {
        getBlogList: async function () {
            let config = {
                method: "GET",
                url: this.blogUrl,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.blogList = response.data.data;
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        async updateStaticSitemap(siteMapXml) {
            try {
                if(!this.staticSitemap) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter static sitemap data!"
                    });
                } else {
                    const data = {
                        siteMap: this.staticSitemap,
                        siteMapXml
                    };
                    const res = await this.createUpdateCMS(this.staticSiteMapUrl, data);
                    if (res.statusCode == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: res.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }

                    const siteMapData = await this.getCmsData(this.staticSiteMapUrl);
                    if(siteMapData?.data?.length) {
                        this.staticSitemap = siteMapData?.data?.[0]?.siteMap;
                    }
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        },

        async generateSitemap() {
            const finalResult =
`<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" 
        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" 
        xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9
            http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
${this.staticSitemap}
${this.dynamicSitemap}
</urlset>`;
            await this.updateStaticSitemap(finalResult);
        }
    }
}