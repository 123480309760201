<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Quiz List
                        </li>
                    </ol>
                </nav>
                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addModal">
                            <i class="bx bxs-plus-square"></i>Add New Quiz Question
                        </button>
                    </div>
                    <!-- Add Modal -->
                    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                        <form @submit.prevent="addQuiz">
                            <div class="modal-dialog modal-md">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Add Quiz</h5>
                                        <button type="button" id="closeButton" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Question:</label>
                                        <textarea v-model="question" class="form-control" cols="30" rows="2"></textarea>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Answer:</label>
                                         <textarea v-model="answer" class="form-control" cols="30" rows="2"></textarea>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Option A:</label>
                                        <input type="text" v-model="optionA" class="form-control">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Option B:</label>
                                        <input type="text" v-model="optionB" class="form-control">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Option C:</label>
                                        <input type="text" v-model="optionC" class="form-control">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Option D:</label>
                                        <input type="text" v-model="optionD" class="form-control">
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                            Close
                                        </button>
                                        <button type="submit" class="btn btn-primary">
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Add Modal -->
                    <!-- Edit Modal -->
                    <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                        <form @submit.prevent="updateQuiz">
                            <div class="modal-dialog modal-md">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Update Quiz</h5>
                                        <button type="button" id="buttonClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Question:</label>
                                        <textarea v-model="singleQuiz.question" class="form-control" cols="30" rows="4"></textarea>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Answer:</label>
                                         <textarea v-model="singleQuiz.answer" class="form-control" cols="30" rows="4"></textarea>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Option A:</label>
                                        <input type="text" v-model="singleQuiz.optionA" class="form-control">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Option B:</label>
                                        <input type="text" v-model="singleQuiz.optionB" class="form-control">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Option C:</label>
                                        <input type="text" v-model="singleQuiz.optionC" class="form-control">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Option D:</label>
                                        <input type="text" v-model="singleQuiz.optionD" class="form-control">
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                            Close
                                        </button>
                                        <button type="submit" class="btn btn-primary">
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Edit Modal -->
                </div>
            </div>
            <h6 class="mb-0 text-uppercase">Quiz List</h6>
            <hr />
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Question</th>
                                    <th>Answer</th>
                                    <th>Option A</th>
                                    <th>Option B</th>
                                    <th>Option C</th>
                                    <th>Option D</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in dataList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ data.question.length > 30 ? data.question.slice(0, 30) + '...' : data.question }}</td>
                                    <td>{{ data.answer.length > 30 ? data.answer.slice(0, 30) + '...' : data.answer }}</td>
                                    <td>{{ data.optionA.length > 30 ? data.optionA.slice(0, 30) + '...' : data.optionA }}</td>
                                    <td>{{ data.optionB.length > 30 ? data.optionB.slice(0, 30) + '...' : data.optionB }}</td>
                                    <td>{{ data.optionC.length > 30 ? data.optionC.slice(0, 30) + '...' : data.optionC }}</td>
                                    <td>{{ data.optionD.length > 30 ? data.optionD.slice(0, 30) + '...' : data.optionD }}</td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <a href="javascript:void(0);" v-on:click="editQuiz(data)" data-bs-toggle="modal" data-bs-target="#editModal" class="edit-button">
                                                <i class="bx bxs-edit text-white"></i>
                                            </a>
                                            <a href="javascript:void(0);" v-on:click="deleteQuiz(data.id)" class="ms-3 delete-button"><i class="bx bxs-trash text-white"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>SL</th>
                                    <th>Question</th>
                                    <th>Answer</th>
                                    <th>Option A</th>
                                    <th>Option B</th>
                                    <th>Option C</th>
                                    <th>Option D</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src='../js/quiz.js'></script>


<style scoped>
.edit-button {
    background-color: #17a00e;
}

.delete-button {
    background-color: #f41127;
}
</style>
