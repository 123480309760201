<template>
  <div class="page-wrapper">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Career List</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Career List</li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group">
            <button @click="$router.push('/add-career')" type="button" class="btn btn-primary">
              <i class="bx bxs-plus-square"></i>Add New Career
            </button>
          </div>
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Career Table</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>JobType</th>
                  <th>Title</th>
                  <th>Store</th>
                  <th>Location</th>
                  <th>Salary</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ row.jobType }}</td>
                  <td>{{ row.title }}</td>
                  <td>{{ row.storeName ? row.storeName : 'N/A' }}</td>
                  <td>{{ row.location }}</td>
                  <td>{{ row.salary }}</td>
                  <td>{{ $filters.formatDate(row.createdAt) }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a
                        href="javascript:void(0);"
                        v-on:click="$router.push('/edit-career/' + row.id)"
                        class="edit-button"
                      >
                        <i class="bx bxs-edit"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        v-on:click="deleteContent(row['id'])"
                        class="ms-3 delete-button"
                      >
                        <i class="bx bxs-trash"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>JobType</th>
                  <th>Title</th>
                  <th>Store</th>
                  <th>Location</th>
                  <th>Salary</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<style scoped>
@import "../../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>

<script src="../js/career.js"></script>